import React from 'react';
import './CopyPreviousWeekModal.css';

const CopyPreviousWeekModal = ({ isOpen, onClose, onConfirm, status, message, shiftCount }) => {
    if (!isOpen) return null;

    return (
        <div className="copy-previous-week-modal">
            <div className="copy-previous-week-modal-content">
                <span className="copy-previous-week-modal-close" onClick={onClose}>&times;</span>
                {status === 'confirm' && (
                    <>
                        <h2>Geçen haftanın vardiyalarını kopyala</h2>
                        <p>{shiftCount} adet vardiyayı kopyalamak üzeresiniz. Devam etmek istiyor musunuz?</p>
                        <div className="copy-previous-week-modal-button-group">
                            <button className="copy-previous-week-modal-cancel-button" onClick={onClose}>İptal</button>
                            <button className="copy-previous-week-modal-confirm-button" onClick={onConfirm}>Onayla</button>
                        </div>
                    </>
                )}
                {status === 'loading' && (
                    <>
                        <h2>İşlem devam ediyor</h2>
                        <div className="copy-previous-week-modal-loader"></div>
                        <p className="copy-previous-week-modal-loading-text">Vardiyalar kopyalanıyor...</p>
                    </>
                )}
                {status === 'success' && (
                    <>
                        <h2>Tamamlandı</h2>
                        <p>{message}</p>
                        <div className="copy-previous-week-modal-button-group">
                            <button className="copy-previous-week-modal-confirm-button" onClick={onClose}>Kapat</button>
                        </div>
                    </>
                )}
                {status === 'error' && (
                    <>
                        <h2>Hata</h2>
                        {Array.isArray(message) ? (
                            <ul className="error-list">
                                {message.map((error, index) => (
                                    <li key={index}>{error}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{message}</p>
                        )}
                        <div className="copy-previous-week-modal-button-group">
                            <button className="copy-previous-week-modal-confirm-button" onClick={onClose}>Kapat</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default CopyPreviousWeekModal;