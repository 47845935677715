import axios from "axios";

const API_BASE_URL = '/surveys';
export const addSurvey= async (survey) => {
    try {
        const response = await axios.post(`${API_BASE_URL}`, { survey: survey }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error adding shifts:", error);
        throw error;
    }
};


export const updateSurvey = async (surveyId, surveyData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/${surveyId}`, { survey: surveyData }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error updating survey:", error);
        throw error;
    }
}

export const findSurvey = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/${id}/find_survey`);
        return response.data;
    } catch (error) {
        console.error("Error finding shifts:", error);
        throw error;
    }
};