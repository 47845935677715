import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["select", "timeRange", "timeDuration"]

  connect() {
    this.selected()
  }

  selected() {
    this.hideFields()
    switch (this.selectTarget.value) {
      case 'time_range':
        this.timeRangeTargets.forEach((element) => {
          element.classList.remove('d-none');
        });
        break;
      case 'time_duration':
        this.timeDurationTargets.forEach((element) => {
          element.classList.remove('d-none');
        });
        break;
    }
  }

  hideFields() {
    this.timeRangeTargets.forEach((element) => {
      element.classList.add('d-none')
    });
    this.timeDurationTargets.forEach((element) => {
      element.classList.add('d-none')
    });
  }
}
