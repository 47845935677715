
import axios from 'axios';

const api = axios.create({
    baseURL: '/', // Veya process.env.REACT_APP_API_BASE_URL kullanabilirsiniz
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

// Basit bir hata yakalama mekanizması
api.interceptors.response.use(
    response => response,
    error => {
        console.error('API Error:', error);
        return Promise.reject(error);
    }
);

export default api;