import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["checkbox", "manual", "auto"]

    connect() {
        this.selected()
    }

    selected() {
        const departmentElement = this.element.querySelector('#approval_flow_department_ids');
        const branchElement = this.element.querySelector('#approval_flow_branch_ids');
        const personElement = this.element.querySelector('#approval_flow_person_ids');
        const jobPositionElement = this.element.querySelector('#approval_flow_job_position_ids');

        const departmentSelected = departmentElement && departmentElement.value !== '';
        const branchSelected = branchElement && branchElement.value !== '';
        const personSelected = personElement && personElement.value !== '';
        const jobPositionSelected = jobPositionElement && jobPositionElement.value !== '';

        if (branchSelected) {
            this.hideAndClearFields(['department', 'person', 'job_position']);
        }
        else if (personSelected) {
            this.hideAndClearFields(['department', 'branch', 'job_position']);
        }
        else if (departmentSelected) {
            this.hideAndClearFields(['branch', 'person']);
        }
        else if (jobPositionSelected) {
            if (!personSelected && branchSelected && departmentSelected) {
                this.hideAndClearFields(['branch']);
            } else {
                this.hideAndClearFields(['department', 'branch', 'person']);
            }
        } else {
            this.showFields(['department', 'branch', 'person', 'job_position']);
        }

        this.hideFields()
        if (this.checkboxTarget.checked === true) {
            this.autoTargets.forEach((element) => {
                element.classList.remove('d-none');
            });
        } else {
            this.manualTargets.forEach((element) => {
                element.classList.remove('d-none');
            });
        }
    }

    hideFields() {
        this.manualTargets.forEach((element) => {
            element.classList.add('d-none')
        });
        this.autoTargets.forEach((element) => {
            element.classList.add('d-none')
        });
    }

    hideAndClearFields(fieldNames) {
        fieldNames.forEach((fieldName) => {
            const field = this.element.querySelector(`#approval_flow_${fieldName}_ids`);
            const container = this.element.querySelector(`#${fieldName}_id`);
            if (container) {
                container.classList.add('d-none')
            }
            if (field) {
                field.classList.add('d-none'); // Hide
                field.value = '';               // Empty value
            }
        });
    }

    showFields(fieldNames) {
        fieldNames.forEach((fieldName) => {
            const field = this.element.querySelector(`#approval_flow_${fieldName}_ids`);
            const container = this.element.querySelector(`#${fieldName}_id`);
            if (container) {
                container.classList.remove('d-none')
            }
            if (field) {
                field.classList.remove('d-none'); // Show
            }
        });
    }
}
