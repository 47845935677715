import React from 'react';
import './DeleteShiftsModal.css';

const DeleteShiftsModal = ({ isOpen, onClose, onConfirm, shiftCount, employeeCount, status, message }) => {
    if (!isOpen) return null;

    return (
        <div className="delete-shifts-modal">
            <div className="delete-shifts-modal-content">
                <span className="delete-shifts-modal-close" onClick={onClose}>&times;</span>
                {status === 'confirm' && (
                    <>
                        <h2>Vardiyaları Sil</h2>
                        <p>
                            Toplamda {shiftCount} atanmış
                            vardiya{shiftCount !== 1 ? '' : ''} için {employeeCount} çalışan{employeeCount !== 1 ? 'lar' : ''}ı
                            sileceksiniz.
                            <br/>
                            Devam etmek istiyor musunuz?
                        </p>

                        <div className="delete-shifts-modal-button-group">
                            <button className="delete-shifts-modal-cancel-button" onClick={onClose}>Vazgeç</button>
                            <button className="delete-shifts-modal-confirm-button" onClick={onConfirm}>Vardiyaları Sil
                            </button>
                        </div>
                    </>
                )}
                {status === 'loading' && (
                    <>
                        <h2>Vardiyaları silme</h2>
                        <div className="delete-shifts-modal-loader"></div>
                        <p className="delete-shifts-modal-loading-text">Vardiyalar siliniyor...</p>
                    </>
                )}
                {status === 'success' && (
                    <>
                        <h2>Tamamlandı</h2>
                        <p>{message}</p>
                        <div className="delete-shifts-modal-button-group">
                            <button className="delete-shifts-modal-confirm-button" onClick={onClose}>Kapat</button>
                        </div>
                    </>
                )}
                {status === 'error' && (
                    <>
                        <h2>Hata</h2>
                        <p>{message}</p>
                        <div className="delete-shifts-modal-button-group">
                            <button className="delete-shifts-modal-confirm-button" onClick={onClose}>Kapat</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DeleteShiftsModal;