import React, { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import InfiniteScroll from "react-infinite-scroll-component";

const LeaveTypeSection = ({ leaveTypes, selectedLeaveType, setSelectedLeaveType, fetchMoreLeaveTypes, hasMore }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredLeaveTypes = leaveTypes.filter(leaveType =>
        leaveType.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const CheckIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="20" height="20">
            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
        </svg>
    );

    return (
        <div className="section">
            <label>İzin Türü:</label>
            <input
                type='text'
                placeholder='İzin türü ara...'
                className='leave-type-search'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <InfiniteScroll
                dataLength={filteredLeaveTypes.length}
                next={fetchMoreLeaveTypes}
                hasMore={hasMore}
                loader={<h4>Yükleniyor...</h4>}
                height={200}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Tüm izin türleri gösteriliyor!</b>
                    </p>
                }
            >
                <RadioGroup value={selectedLeaveType} onChange={setSelectedLeaveType} className='leave-type-list'>
                    {filteredLeaveTypes.map((leaveType) => (
                        <RadioGroup.Option key={leaveType.id} value={leaveType.id} className={({ active, checked }) =>
                            `leave-type-item ${checked ? 'selected' : ''} ${active ? 'active' : ''}`
                        }>
                            {({ checked }) => (
                                <>
                                    <span className="leave-type-label">{leaveType.title}</span>
                                    {checked && <CheckIcon />}
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </RadioGroup>
            </InfiniteScroll>
        </div>
    );
};

export default LeaveTypeSection;