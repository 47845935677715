import { useState, useEffect } from 'react';
import * as shiftService from '../services/shiftService';

export const useUsers = (branchId) => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            if (!branchId) {
                setUsers([]);
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            try {
                const data = await shiftService.fetchUsers(branchId);
                setUsers(data);
                setError(null);
            } catch (err) {
                console.error("Error fetching users:", err);
                setError("Kullanıcılar yüklenirken bir hata oluştu.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, [branchId]);

    return { users, isLoading, error };
};