import { Controller } from "@hotwired/stimulus"
import qrCodeStyling from 'qr-code-styling'

// Connects to data-controller="qr-code"
export default class extends Controller {
  connect() {
    const qrCode = new QRCodeStyling({
      width: 500,
      height: 500,
      data: "123123",
      image: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50",
      imageOptions:{
        "hideBackgroundDots":true,
        "imageSize":0.4,
        "margin":0
      },
      dotsOptions: {
        color: "#000000",
        type: "dots"
      },
      cornersSquareOptions:{
        "type":"extra-rounded",
        "color":"#000000"
      },
      cornersDotOptions:{
        "type":"square",
        "color":"#000000"
      },
      backgroundOptions: {
        color: "#FFFFFF",
      }
    });
    qrCode.append(document.getElementById("canvas"));
  }
}
