import React, { useState, useMemo } from 'react';
import { Popover } from '@headlessui/react';
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/20/solid';

const AssignSection = ({ users, selectedEmployees, setSelectedEmployees, selectedCells }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showAllEmployees, setShowAllEmployees] = useState(false);

    const filteredUsers = users.filter(user =>
        `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const selectedCellEmployees = useMemo(() => {
        return selectedCells.map(cell => {
            const user = users.find(u => u.id === cell.employeeId);
            return user ? `${user.first_name} ${user.last_name}` : '';
        }).filter(Boolean);
    }, [selectedCells, users]);

    const uniqueDates = useMemo(() => {
        const dateSet = new Set(selectedCells.map(cell => cell.date));
        return Array.from(dateSet);
    }, [selectedCells]);

    const handleEmployeeSelect = (userId) => {
        setSelectedEmployees(prev =>
            prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
        );
    };

    return (
        <div className="assign-section">
            <label className="assign-section-label">Kişilere ata:</label>
            {selectedCells.length > 0 ? (
                <>
                    <label>Seçili kişiler:</label>
                    <div className="selected-employees">
                        {selectedCellEmployees.length > 10 && !showAllEmployees
                            ? `${selectedCellEmployees.slice(0, 10).join(", ")}... daha fazla`
                            : selectedCellEmployees.join(", ")}
                    </div>
                    {selectedCellEmployees.length > 10 && (
                        <button onClick={() => setShowAllEmployees(!showAllEmployees)}>
                            {showAllEmployees ? 'daha az' : 'daha fazla'}
                        </button>
                    )}
                    <div>
                        Seçilen tarihler: {uniqueDates.join(", ")}
                    </div>
                </>
            ) : (
                <div className="assign-section-select-container">
                    <Popover className="assign-section-popover">
                        <Popover.Button className="assign-section-button">
                            <span>{selectedEmployees.length ? `${selectedEmployees.length} kullanıcı seçildi` : 'Seçiniz'}</span>
                            <ChevronDownIcon className="assign-section-chevron"/>
                        </Popover.Button>
                        <Popover.Panel className="assign-section-panel">
                            <div className="assign-section-search">
                                <FunnelIcon className="assign-section-search-icon"/>
                                <input
                                    type="text"
                                    placeholder="Ara..."
                                    className="assign-section-search-input"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                            <div className="assign-section-user-list">
                                {filteredUsers.map(user => (
                                    <div key={user.id} className="assign-section-user-item">
                                        <input
                                            type="checkbox"
                                            id={`user-${user.id}`}
                                            checked={selectedEmployees.includes(user.id)}
                                            onChange={() => handleEmployeeSelect(user.id)}
                                            className="assign-section-checkbox"
                                        />
                                        <label htmlFor={`user-${user.id}`} className="assign-section-user-name">
                                            {user.first_name} {user.last_name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </Popover.Panel>
                    </Popover>
                </div>
            )}
        </div>
    );
};

export default AssignSection;
