import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-drawer"
export default class extends Controller {

  connect() {
  }

  static targets = ["drawer", "data-trigger"];


  openDrawer(event) {
    event.preventDefault()
    event.stopPropagation();

    //   var offcanvas_id =  $(this).attr('data-trigger');
    //
    // this.dataTrigerTarget.setAttribute('data-trigger')
    //
    // offcanvas_id.toggleClass("show");
    // let item = event.target("body")
    // item.toggleClass("offcanvas-active");
    // event.target(".screen-overlay").toggleClass("show");
    console.log(event);
    console.log('openDrawer called');
  }

}


// $("[data-trigger]").on("click", function(e){
//   e.preventDefault();
//   e.stopPropagation();
//   var offcanvas_id =  $(this).attr('data-trigger');
//   $(offcanvas_id).toggleClass("show");
//   $('body').toggleClass("offcanvas-active");
//   $(".screen-overlay").toggleClass("show");
// });
//
// $(".btn-close, .screen-overlay").click(function(e){
//   $(".screen-overlay").removeClass("show");
//   $(".offcanvas").removeClass("show");
//   $("body").removeClass("offcanvas-active");
// });