import React, { useState, useEffect, useCallback } from "react";
import { format, addDays, startOfWeek } from "date-fns";
import ScheduleHeader from "./ScheduleHeader";
import ScheduleFooter from "./ScheduleFooter";
import ToolBar from "./ToolBar/ToolBar";
import ShiftGrid from "./ShiftGrid";
import ErrorModal from './ErrorModal';
import ApproveScheduleModal from './ApproveScheduleModal/ApproveScheduleModal';
import CopyPreviousWeekModal from './ToolBar/CopyPreviousWeekModal/CopyPreviousWeekModal';
import PlanFromHistoryModal from './ToolBar/PlanFromHistoryModal/PlanFromHistoryModal';

import { useShiftData, transformShiftData } from '../../hooks/useShiftData';
import { useBranchData } from '../../hooks/useBranchData';
import { useUsers } from '../../hooks/useUsers';
import * as shiftService from '../../services/shiftService';
import "./ShiftSchedule.css";

const ShiftSchedule = () => {
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedCells, setSelectedCells] = useState([]);
    const [currentWeekDate, setCurrentWeekDate] = useState(new Date());
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isCopyPreviousWeekModalOpen, setIsCopyPreviousWeekModalOpen] = useState(false);
    const [copyModalStatus, setCopyModalStatus] = useState('confirm');
    const [copyModalMessage, setCopyModalMessage] = useState('');
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [approveModalStatus, setApproveModalStatus] = useState('confirm');
    const [approveModalMessage, setApproveModalMessage] = useState('');
    const [scheduleStatus, setScheduleStatus] = useState('draft');
    const [displayedEmployeeCount, setDisplayedEmployeeCount] = useState(0);
    const [lockApprovedShifts, setLockApprovedShifts] = useState(false);
    const [viewMode, setViewMode] = useState('weekly');
    const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
    const [isPlanFromHistoryModalOpen, setIsPlanFromHistoryModalOpen] = useState(false);
    const [planFromHistoryStatus, setPlanFromHistoryStatus] = useState('confirm');
    const [planFromHistoryMessage, setPlanFromHistoryMessage] = useState('');
    const { shiftData, setShiftData, leaveData, holidayData, isLoading, error } = useShiftData(
        selectedBranchId,
        currentWeekDate,
        setScheduleStatus
    );
    const { branches } = useBranchData();
    const { users } = useUsers(selectedBranchId);

    const [weekDays, setWeekDays] = useState([]);



    useEffect(() => {
        const handleResize = () => {
            setIsLandscape(window.innerWidth > window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);


    useEffect(() => {
        const generateWeekDays = (date) => {
            const start = startOfWeek(date, { weekStartsOn: 1 });
            const days = [];
            for (let i = 0; i < (viewMode === 'weekly' ? 7 : 28); i++) {
                const day = addDays(start, i);
                days.push({
                    fullDate: format(day, "yyyy-MM-dd"),
                    displayDate: format(day, "EEE d")
                });
            }
            return days;
        };

        setWeekDays(generateWeekDays(currentWeekDate));
    }, [currentWeekDate, viewMode]);

    useEffect(() => {
        const fetchShiftSettings = async () => {
            try {
                const settings = await shiftService.fetchShiftSettings();
                setLockApprovedShifts(settings.lock_approved_shifts);
            } catch (error) {
                console.error('Error fetching shift settings:', error);
                setErrorMessage("Vardiya ayarları yüklenirken bir hata oluştu.");
                setShowError(true);
            }
        };

        fetchShiftSettings();
    }, []);

    const handleViewModeChange = useCallback((newViewMode) => {
        setViewMode(newViewMode);
    }, []);

    const handleDateChange = useCallback((date) => {
        setCurrentWeekDate(date);
        setSelectedCells([]);
        setSelectedEmployees([]);
    }, []);

    const handleBranchChange = useCallback((event) => {
        const branchId = event.target.value;
        setSelectedBranchId(branchId);
    }, []);

    const handleShiftsAdded = useCallback((newShifts) => {
        setShiftData(prevShiftData => {
            const updatedShiftData = {...prevShiftData};
            newShifts.forEach(shift => {
                const userId = shift.user_id;
                const dayOfWeek = format(new Date(shift.date_of_shift), "EEE d");
                if (!updatedShiftData[userId]) updatedShiftData[userId] = {};
                if (!updatedShiftData[userId][dayOfWeek]) updatedShiftData[userId][dayOfWeek] = [];
                updatedShiftData[userId][dayOfWeek].push({
                    id: shift.id,
                    name: shift.shift.name,
                    color: shift.shift.color_code,
                    status: shift.status,
                    calculated_shift_time: shift.shift.calculated_shift_time || 0,
                    slack_time: shift.shift.slack_time || 0,
                });
            });
            return updatedShiftData;
        });
        setSelectedCells([]);
        setScheduleStatus('draft');
    }, [setShiftData]);

    const handleDeleteShift = useCallback(async (shiftId) => {
        try {
            await shiftService.deleteShift(shiftId);
            // Shift verisini güncelleyin
            setShiftData(prevShiftData => {
                const updatedShiftData = {...prevShiftData};
                // Silinen shift'i bulup kaldırın
                for (const userId in updatedShiftData) {
                    for (const day in updatedShiftData[userId]) {
                        updatedShiftData[userId][day] = updatedShiftData[userId][day].filter(shift => shift.id !== shiftId);
                    }
                }
                return updatedShiftData;
            });
        } catch (error) {
            setErrorMessage("Vardiya silinirken bir hata oluştu.");
            setShowError(true);
        }
    }, [setShiftData]);

    const handleDeleteLeave = useCallback(async (leaveId) => {
        try {
            await shiftService.deleteLeave(leaveId);
            // Leave verisini güncelleyin
            // Bu örnekte leave verisi ayrı bir state'te tutulduğu için,
            // benzer bir güncelleme işlemi yapmanız gerekebilir
        } catch (error) {
            setErrorMessage("İzin silinirken bir hata oluştu.");
            setShowError(true);
        }
    }, []);

    const handleCopyPreviousWeek = useCallback(async () => {
        try {
            setCopyModalStatus('loading');
            setIsCopyPreviousWeekModalOpen(true);
            const response = await shiftService.copyPreviousWeek(selectedCells, selectedBranchId);
            if (response && response.shifts) {
                setShiftData(prevShiftData => {
                    const updatedShiftData = {...prevShiftData};
                    response.shifts.forEach(shift => {
                        const userId = shift.user_id;
                        const dayOfWeek = format(new Date(shift.date_of_shift), "EEE d");
                        if (!updatedShiftData[userId]) updatedShiftData[userId] = {};
                        if (!updatedShiftData[userId][dayOfWeek]) updatedShiftData[userId][dayOfWeek] = [];
                        updatedShiftData[userId][dayOfWeek].push({
                            id: shift.id,
                            name: shift.shift.name,
                            color: shift.shift.color_code,
                            status: shift.status,
                            calculated_shift_time: shift.shift.calculated_shift_time || 0,
                            slack_time: shift.shift.slack_time || 0,
                        });
                    });
                    return updatedShiftData;
                });
                setCopyModalStatus('success');
                setCopyModalMessage("Seçili günler için geçen haftanın vardiyaları başarıyla kopyalandı!");
            } else {
                throw new Error("No shifts data in the response");
            }
        } catch (error) {
            console.error("Geçen haftanın vardiyaları kopyalanırken hata oluştu:", error);
            setCopyModalStatus('error');
            setCopyModalMessage(error.response?.data?.errors || 'Bir hata oluştu. Lütfen tekrar deneyin.');
        }
    }, [selectedCells, selectedBranchId, setShiftData]);

    const handleApproveSchedule = useCallback(() => {
        setIsApproveModalOpen(true);
    }, []);

    const handleConfirmApprove = useCallback(async () => {
        try {
            setApproveModalStatus('loading');
            const response = await shiftService.approveShifts(
                currentWeekDate,
                addDays(currentWeekDate, 6),
                selectedBranchId
            );
            if (response.success) {
                setApproveModalStatus('success');
                setApproveModalMessage('Plan başarıyla onaylandı.');
                // Vardiya verilerini yeniden yükle
                const updatedShiftData = await shiftService.fetchShiftData(currentWeekDate, selectedBranchId);
                setShiftData(transformShiftData(updatedShiftData.shifts, setScheduleStatus));
                setScheduleStatus('approved');
            } else {
                throw new Error(response.message || 'Onaylama işlemi başarısız oldu.');
            }
        } catch (error) {
            setApproveModalStatus('error');
            setApproveModalMessage(error.message || 'Plan onaylanırken bir hata oluştu. Lütfen tekrar deneyin.');
        }
    }, [currentWeekDate, selectedBranchId, setShiftData, setScheduleStatus]);

    const handleSelectAllEmployees = useCallback((isChecked) => {
        if (isChecked) {
            const allEmployeeIds = users.map(user => user.id);
            setSelectedEmployees(allEmployeeIds);
            const allCells = users.flatMap(user =>
                weekDays.map(day => ({
                    employeeId: user.id,
                    date: day.fullDate
                }))
            );
            setSelectedCells(allCells);
        } else {
            setSelectedEmployees([]);
            setSelectedCells([]);
        }
    }, [users, weekDays]);

    const handleDeleteShifts = useCallback(async (shiftsToDelete) => {
        try {
            const nonEmptyShifts = shiftsToDelete.filter(shift => {
                const userId = shift.user_id;
                const dayOfWeek = format(new Date(shift.date), "EEE d");
                return shiftData[userId] && shiftData[userId][dayOfWeek] && shiftData[userId][dayOfWeek].length > 0;
            });

            if (nonEmptyShifts.length === 0) {
                return { success: true, message: "Silinecek vardiya bulunamadı." };
            }

            const response = await shiftService.deleteShifts(nonEmptyShifts);
            if (response.success) {
                setShiftData(prevShiftData => {
                    const updatedShiftData = {...prevShiftData};
                    response.deleted_shifts.forEach(deletedShift => {
                        const userId = deletedShift.user_id;
                        const dayOfWeek = format(new Date(deletedShift.date), "EEE d");
                        if (updatedShiftData[userId] && updatedShiftData[userId][dayOfWeek]) {
                            updatedShiftData[userId][dayOfWeek] = [];
                        }
                    });
                    return updatedShiftData;
                });
                return { success: true, message: `${response.deleted_shifts.length} vardiya başarıyla silindi.` };
            } else {
                throw new Error(response.message || 'Bazı vardiyalar silinemedi.');
            }
        } catch (error) {
            console.error("Error deleting shifts:", error);
            return { success: false, message: error.message || "Vardiyalar silinirken bir hata oluştu." };
        }
    }, [shiftData, setShiftData]);

    const handlePlanFromHistory = async (weeksAgo) => {
        try {
            setPlanFromHistoryStatus('loading');
            const response = await shiftService.planFromHistory(currentWeekDate, weeksAgo, selectedBranchId);
            if (response.success) {
                setPlanFromHistoryStatus('success');
                setPlanFromHistoryMessage('Planlama başarıyla tamamlandı.');

                // Yeni eklenen vardiyaları mevcut shiftData'ya ekleyelim
                setShiftData(prevData => {
                    const updatedData = {...prevData};
                    response.shifts.forEach(shift => {
                        const userId = shift.user_id;
                        const dayOfWeek = format(new Date(shift.date_of_shift), "EEE d");
                        if (!updatedData[userId]) updatedData[userId] = {};
                        if (!updatedData[userId][dayOfWeek]) updatedData[userId][dayOfWeek] = [];
                        updatedData[userId][dayOfWeek].push({
                            id: shift.id,
                            name: shift.shift.name,
                            color: shift.shift.color_code,
                            status: shift.status,
                            calculated_shift_time: shift.shift.calculated_shift_time || 0,
                            slack_time: shift.shift.slack_time || 0,
                        });
                    });
                    return updatedData;
                });

                setTimeout(() => {
                    closePlanFromHistoryModal();
                }, 2000);
            } else {
                throw new Error(response.message || 'Planlama işlemi başarısız oldu.');
            }
        } catch (error) {
            console.error('Geçmiş verilerden planlama yapılırken hata oluştu:', error);
            setPlanFromHistoryStatus('error');
            setPlanFromHistoryMessage(error.message || 'Bir hata oluştu. Lütfen tekrar deneyin.');
            setTimeout(() => {
                closePlanFromHistoryModal();
            }, 2000);
        }
    };
    const closePlanFromHistoryModal = () => {
        setIsPlanFromHistoryModalOpen(false);
        setPlanFromHistoryStatus('confirm');
        setPlanFromHistoryMessage('');
    };


    return (
        <>
            <div id="orientation-warning" className="orientation-warning">
                <div className="orientation-content">
                    <p>Lütfen cihazınızı yatay konuma çevirin</p>
                    <div className="phone-icon">📱</div>
                </div>
            </div>
            {isLandscape && (
                <div className="shift-schedule">
                    <ScheduleHeader
                        scheduleStatus={scheduleStatus}
                        currentWeekDate={currentWeekDate}
                        onDateChange={handleDateChange}
                        onApproveSchedule={handleApproveSchedule}
                        isLoading={isLoading}
                        viewMode={viewMode}
                    />
                    <ToolBar
                        branches={branches}
                        onBranchChange={handleBranchChange}
                        selectedBranch={selectedBranchId}
                        users={users}
                        startDate={currentWeekDate}
                        endDate={addDays(currentWeekDate, 6)}
                        selectedEmployees={selectedEmployees}
                        setSelectedEmployees={setSelectedEmployees}
                        selectedCells={selectedCells}
                        setSelectedCells={setSelectedCells}
                        onCopyPreviousWeek={handleCopyPreviousWeek}
                        onShiftsAdded={handleShiftsAdded}
                        handleSelectAllEmployees={handleSelectAllEmployees}
                        onDeleteShifts={handleDeleteShifts}
                        viewMode={viewMode}
                        onViewModeChange={handleViewModeChange}
                        onPlanFromHistory={() => setIsPlanFromHistoryModalOpen(true)}

                    />
                    {!selectedBranchId ? (
                        <div className="no-branch-selected">
                            <p>Lütfen planlama yapmak için bir şube seçin.</p>
                        </div>
                    ) : (
                        <ShiftGrid
                            selectedEmployees={selectedEmployees}
                            setSelectedEmployees={setSelectedEmployees}
                            selectedCells={selectedCells}
                            setSelectedCells={setSelectedCells}
                            shifts={shiftData}
                            leaves={leaveData}
                            holidayCalendarDays={holidayData}
                            users={users}
                            onDeleteShift={handleDeleteShift}
                            onDeleteLeave={handleDeleteLeave}
                            isLoading={isLoading}
                            setDisplayedEmployeeCount={setDisplayedEmployeeCount}
                            lockApprovedShifts={lockApprovedShifts}
                            currentWeekDate={currentWeekDate}
                            weekDays={weekDays}
                            shiftData={shiftData}
                            viewMode={viewMode}
                            setShiftData={setShiftData}

                        />
                    )}
                    <ScheduleFooter displayedEmployeeCount={displayedEmployeeCount}/>

                    <ErrorModal
                        showError={showError}
                        errorMessage={errorMessage}
                        onClose={() => setShowError(false)}
                    />
                    <CopyPreviousWeekModal
                        isOpen={isCopyPreviousWeekModalOpen}
                        onClose={() => {
                            setIsCopyPreviousWeekModalOpen(false);
                            setCopyModalStatus('confirm');
                            setSelectedCells([]);
                            setSelectedEmployees([]);
                        }}
                        onConfirm={handleCopyPreviousWeek}
                        status={copyModalStatus}
                        message={copyModalMessage}
                        shiftCount={selectedCells.length}
                    />
                    <ApproveScheduleModal
                        isOpen={isApproveModalOpen}
                        onClose={() => {
                            setIsApproveModalOpen(false);
                            setApproveModalStatus('confirm');
                            setApproveModalMessage('');
                        }}
                        onConfirm={handleConfirmApprove}
                        status={approveModalStatus}
                        message={approveModalMessage}
                        startDate={format(currentWeekDate, "d MMMM yyyy")}
                        endDate={format(addDays(currentWeekDate, 6), "d MMM yyyy")}
                    />
                    <PlanFromHistoryModal
                        isOpen={isPlanFromHistoryModalOpen}
                        onClose={closePlanFromHistoryModal}
                        onConfirm={handlePlanFromHistory}
                        status={planFromHistoryStatus}
                        message={planFromHistoryMessage}
                    />
                </div>
            )}
        </>

    );
};

export default React.memo(ShiftSchedule);