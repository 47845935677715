// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import {Controller} from "stimulus"
import {BrowserQRCodeReader} from '@zxing/library';

export default class extends Controller {

    initialize() {
    // # yatay tirn
        const codeReader = new BrowserQRCodeReader();

        codeReader
            .decodeFromInputVideoDevice(undefined, 'video')
            .then((result) => {
                console.log(result.text)
                document.getElementById('result').textContent = result.text
                Rails.ajax(
                    {
                        url: 'qr_code_scanner/${result.text}/scan',
                        type: 'GET'
                    }
                )
            })
            .catch(err => console.error(err));
    }
}
