import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["addButton"];

  connect() {
    console.log("Hover controller connected");
    this.hideAddButton(); // Ensure button is hidden on load
  }

  showAddButton() {
    console.log("Showing add button");
    this.addButtonTarget.classList.remove('d-none');
  }

  hideAddButton() {
    console.log("Hiding add button");
    this.addButtonTarget.classList.add('d-none');
  }
}