import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "leaveType"]

  connect() {
  }

  leaveConfirmationPopup(event) {
    let selectedValue = this.leaveTypeTarget.value;
    let selectedOption = this.leaveTypeTarget.querySelector(`option[value="${selectedValue}"]`);
    let isMedicalLeave = selectedOption.getAttribute('data-is-medical-leave') === 'true';

    if (isMedicalLeave) {
      if (!confirm('Sağlık iznimle ilgili verilerin Passgage üzerinden işlenmesine ve İşveren bünyesinde yetkili kişilerin erişimine açılmasına açık rıza göstermekteyim.', 'İptal')) {
        event.preventDefault();
        alert('Lütfen izin talebini alternatif kanal üzerinden iletmek amacıyla İşveren İnsan Kaynakları yetkisiyle veya İşyeri Hekimiyle iletişime geçiniz.', 'Tamam');
        location.reload();
      }
    }
  }

  toggleUseHourlyLeave(event) {
    if (event.target.checked) {
      document.getElementById("dailyLeaveContainer").style.display = "none";
      document.getElementById("hourlyLeaveContainer").style.display = "block";
    } else {
      document.getElementById("dailyLeaveContainer").style.display = "block";
      document.getElementById("hourlyLeaveContainer").style.display = "none";
    }
  }
}
