import React, { useEffect } from "react";

import { format, addDays, startOfWeek, isValid } from 'date-fns';


import './PeriodSection.css';

const PeriodSection = ({
                           selectedDays,
                           setSelectedDays,
                           startDate,
                           setStartDate,
                           endDate,
                           setEndDate,
                           isRestDayDrawer,
                           isHourlyLeave,
                           setIsHourlyLeave,
                           startTime,
                           setStartTime,
                           endTime,
                           setEndTime,
                           currentWeekDate
                       }) => {
    const days = ["Pzt", "Sal", "Çar", "Per", "Cum", "Cts", "Paz"];

    useEffect(() => {
        if (currentWeekDate && isValid(new Date(currentWeekDate))) {
            const startDate = startOfWeek(new Date(currentWeekDate), { weekStartsOn: 1 });
            const endDate = addDays(startDate, 6);

            setStartDate(format(startDate, 'yyyy-MM-dd'));
            setEndDate(format(endDate, 'yyyy-MM-dd'));
        } else {
            console.error('Invalid currentWeekDate:', currentWeekDate);
            const today = startOfWeek(new Date(), { weekStartsOn: 1 });
            setStartDate(format(today, 'yyyy-MM-dd'));
            setEndDate(format(addDays(today, 6), 'yyyy-MM-dd'));
        }
    }, [currentWeekDate]);

    const toggleDaySelection = (day) => {
        setSelectedDays(prev =>
            prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
        );
    };

    return (
        <div className='period-section'>
            <h3 className="period-section-title">Uygulama dönemi:</h3>
            {isRestDayDrawer && (
                <div className="hourly-leave-checkbox">
                    <label>
                        <input
                            type="checkbox"
                            checked={isHourlyLeave}
                            onChange={(e) => setIsHourlyLeave(e.target.checked)}
                        />
                        Saatlik İzin
                    </label>
                </div>
            )}
            <div className="date-inputs">
                <div className="date-input-group">
                    <label>Başlangıç tarihi:</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="date-input"
                    />
                    {isRestDayDrawer && isHourlyLeave && (
                        <input
                            type="time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            className="time-input"
                        />
                    )}
                </div>
                <div className="date-input-group">
                    <label>Bitiş tarihi:</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="date-input"
                    />
                    {isRestDayDrawer && isHourlyLeave && (
                        <input
                            type="time"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            className="time-input"
                        />
                    )}
                </div>
            </div>
            <div className="days-selection">
                <label>Günleri tekrarla (opsiyonel):</label>
                <div className='days-buttons'>
                    {days.map((day, index) => (
                        <button
                            key={index}
                            className={`day-button ${selectedDays.includes(day) ? "selected" : ""}`}
                            onClick={() => toggleDaySelection(day)}
                        >
                            {day}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PeriodSection;