import { Controller } from "@hotwired/stimulus"
import FlatpickrTime from 'stimulus-flatpickr'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import 'flatpickr/dist/themes/airbnb.css'
// Connects to data-controller="flatpickr"
export default class extends FlatpickrTime {


  initialize() {
    this.config = {
      locale: Turkish,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true
    };
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    console.log('the callback returns the selected dates', selectedDates)
    console.log('but returns it also as a string', dateStr)
    console.log('and the flatpickr instance', instance)
  }
}
