// ApproveScheduleModal.jsx
import React from 'react';
import './ApproveScheduleModal.css';

const ApproveScheduleModal = ({ isOpen, onClose, onConfirm, startDate, endDate, status, message }) => {
    if (!isOpen) return null;

    return (
        <div className="approve-schedule-modal">
            <div className="approve-schedule-modal-content">
                <span className="approve-schedule-modal-close" onClick={onClose}>&times;</span>
                {status === 'confirm' && (
                    <>
                        <h2>Planı Onayla</h2>
                        <p>
                            {startDate} - {endDate} tarihleri arasındaki planı onaylamak üzeresiniz.
                            Bu tarih aralığı dışındaki değişiklikler onaylanmayacaktır.
                        </p>
                        <div className="approve-schedule-modal-info">
                            Başka bir planı onaylamak için Vardiya Yönetimi tarih seçicisini kullanın
                            ve Planı Onayla düğmesine basın.
                        </div>
                        <div className="approve-schedule-modal-button-group">
                            <button className="approve-schedule-modal-cancel-button" onClick={onClose}>İptal</button>
                            <button className="approve-schedule-modal-confirm-button" onClick={onConfirm}>Planı Onayla</button>
                        </div>
                    </>
                )}
                {status === 'loading' && (
                    <>
                        <h2>İşlem devam ediyor</h2>
                        <div className="approve-schedule-modal-loader"></div>
                        <p className="approve-schedule-modal-loading-text">Plan onaylanıyor...</p>
                    </>
                )}
                {status === 'success' && (
                    <>
                        <h2>Tamamlandı</h2>
                        <p>{message}</p>
                        <div className="approve-schedule-modal-button-group">
                            <button className="approve-schedule-modal-confirm-button" onClick={onClose}>Kapat</button>
                        </div>
                    </>
                )}
                {status === 'error' && (
                    <>
                        <h2>Hata</h2>
                        <p>{message}</p>
                        <div className="approve-schedule-modal-button-group">
                            <button className="approve-schedule-modal-confirm-button" onClick={onClose}>Kapat</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ApproveScheduleModal;