import { useState, useEffect } from 'react';
import * as shiftService from '../services/shiftService';

export const useBranchData = () => {
    const [branches, setBranches] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBranches = async () => {
            setIsLoading(true);
            try {
                const data = await shiftService.fetchBranches();
                setBranches(data);
                setError(null);
            } catch (err) {
                console.error("Error fetching branches:", err);
                setError("Şubeler yüklenirken bir hata oluştu.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchBranches();
    }, []);

    return { branches, isLoading, error };
};