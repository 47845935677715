import api from './api';
import { format, startOfWeek, addDays } from 'date-fns';



export const fetchShiftData = async (date, branchId) => {
    const start = startOfWeek(date, { weekStartsOn: 1 });
    const end = addDays(start, 6);

    try {
        const [shiftsResponse, leavesResponse, holidaysResponse] = await Promise.all([
            api.get(`/shift_planning/shifts`, {
                params: {
                    start_date: format(start, "yyyy-MM-dd"),
                    end_date: format(end, "yyyy-MM-dd"),
                    branch_id: branchId,
                },
            }),
            api.get(`/shift_planning/leaves`, {
                params: {
                    start_date: format(start, "yyyy-MM-dd"),
                    end_date: format(end, "yyyy-MM-dd"),
                    branch_id: branchId,
                },
            }),
            api.get(`/shift_planning/holiday_calendar_days`, {
                params: {
                    start_date: format(start, "yyyy-MM-dd"),
                    end_date: format(end, "yyyy-MM-dd"),
                    branch_id: branchId,
                },
            }),
        ]);

        return {
            shifts: shiftsResponse.data,
            leaves: leavesResponse.data,
            holidays: holidaysResponse.data,
        };
    } catch (error) {
        console.error("Error fetching shift data:", error);
        throw error;
    }
};

export const fetchBranches = async () => {
    try {
        const response = await api.get(`/shift_planning/branches`);
        return response.data;
    } catch (error) {
        console.error("Error fetching branches:", error);
        throw error;
    }
};

export const fetchUsers = async (branchId) => {
    try {
        const response = await api.get(`/shift_planning/users`, {
            params: { branch_id: branchId },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
    }
};

export const fetchShiftSettings = async () => {
    try {
        const response = await api.get(`/shift_planning/shift_settings`);
        return response.data;
    } catch (error) {
        console.error('Error fetching shift settings:', error);
        throw error;
    }
};

export const deleteShift = async (shiftId) => {
    try {
        await api.delete(`/shift_planning/shifts/${shiftId}`);
    } catch (error) {
        console.error("Error deleting shift:", error);
        throw error;
    }
};

export const deleteLeave = async (leaveId) => {
    try {
        await api.delete(`/shift_planning/leaves/${leaveId}`);
    } catch (error) {
        console.error("Error deleting leave:", error);
        throw error;
    }
};

export const addShifts = async (shifts) => {
    try {
        const response = await api.post(`/shift_planning/user_shifts`, { user_shifts: shifts });
        return response.data;
    } catch (error) {
        console.error("Error adding shifts:", error);
        throw error;
    }
};

export const deleteShifts = async (shiftsToDelete) => {
    try {
        const response = await api.post(`/shift_planning/delete_shifts`, { shifts: shiftsToDelete });
        return response.data;
    } catch (error) {
        console.error("Error deleting shifts:", error);
        throw error;
    }
};

export const copyPreviousWeek = async (selectedCells, branchId) => {
    try {
        const response = await api.post(`/shift_planning/copy_previous_week`, {
            selected_cells: selectedCells,
            branch_id: branchId
        });
        return response.data;
    } catch (error) {
        console.error("Error copying previous week:", error);
        throw error;
    }
};

export const approveShifts = async (startDate, endDate, branchId) => {
    try {
        const response = await api.post(`/shift_planning/approve_shifts`, {
            start_date: format(startDate, "yyyy-MM-dd"),
            end_date: format(endDate, "yyyy-MM-dd"),
            branch_id: branchId
        });
        return response.data;
    } catch (error) {
        console.error("Error approving shifts:", error);
        throw error;
    }
};

export const fetchCompanyShifts = async (page, searchQuery) => {
    try {
        const response = await api.get(`/shift_planning/company_shifts`, {
            params: {
                page,
                per_page: 20,
                q: {
                    name_cont: searchQuery
                }
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching company shifts:", error);
        throw error;
    }
};

export const moveShift = async (moveData) => {
    console.log('moveShift called with:', moveData);
    try {
        const response = await api.post(`/shift_planning/move_shift`, moveData);
        console.log('moveShift response:', response.data);
        if (!response.data.success) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (error) {
        console.error('Error moving shift:', error);
        throw error.response?.data?.message || error.message || 'An unexpected error occurred';
    }
};


export const planFromHistory = async (startDate, weeksAgo, branchId) => {
    try {
        const response = await api.post(`/shift_planning/plan_from_history`, {
            start_date: format(startDate, "yyyy-MM-dd"),
            weeks_ago: weeksAgo,
            branch_id: branchId
        });
        return response.data;
    } catch (error) {
        console.error("Error planning from history:", error);
        throw error;
    }
};

export const fetchUserPermissions = async () => {
    try {
        const response = await api.get(`/shift_planning/user_permissions`);
        return response.data;
    } catch (error) {
        console.error("Error fetching user permissions:", error);
        return {};
    }
};