import React, { useState } from "react";
import "./CopyModal.css";

const CopyModal = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState("");
  const [coverageDate, setCoverageDate] = useState("");

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleCopy = () => {
    // Copy logic here
    onClose();
  };

  return (
    <div className='modal'>
      <div className='modal-content'>
        {step === 1 && (
          <div>
            <h3>Copy a specific period</h3>
            <div className='step'>
              <label>Schedule</label>
              <div className='radio-group'>
                <input type='radio' name='schedule' onChange={() => {}} />
                Specify the period to copy the shifts assigned in the schedule.
              </div>
            </div>
            <div className='step'>
              <label>Coverage</label>
              <div className='radio-group'>
                <input type='radio' name='coverage' onChange={() => {}} />
                Specify the period to copy the shifts added in the Coverage
                section.
                <input
                  type='text'
                  value={coverageDate}
                  onChange={(e) => setCoverageDate(e.target.value)}
                  placeholder='07/15/2024 – 07/21/2024'
                />
                <div>
                  7 days will be copied to the destination schedule period.
                </div>
              </div>
            </div>
            <div className='button-group'>
              <button onClick={handleNext} className='next-button'>
                Next
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            <h3>Copy a specific period</h3>
            <div className='step'>
              <label>Destination</label>
              <input
                type='text'
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                placeholder='07/22/2024'
              />
              <div>7 days will be pasted to the destination period.</div>
            </div>
            <div className='button-group'>
              <button onClick={handleBack} className='back-button'>
                Back
              </button>
              <button onClick={handleCopy} className='copy-button'>
                Copy
              </button>
            </div>
          </div>
        )}
        <button onClick={onClose} className='close-button'>
          Close
        </button>
      </div>
    </div>
  );
};

export default CopyModal;
