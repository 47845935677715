import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="device-schedule-repeat-select"
export default class extends Controller {
  static targets = ["select", "once", "daily", "custom"]

  connect() {
    this.selected()
  }

  selected() {
    this.hideFields()
    switch (this.selectTarget.value) {
      case 'once':
        this.onceTarget.classList.remove('d-none')
        break;
      case 'daily':
        this.onceTarget.classList.remove('d-none')
        this.dailyTarget.classList.remove('d-none')
        break;
      case 'custom':
        this.customTarget.classList.remove('d-none')
        break;
    }
  }

  hideFields() {
    this.onceTarget.classList.add('d-none')
    this.dailyTarget.classList.add('d-none')
    this.customTarget.classList.add('d-none')
  }
}
