import api from './api';
export const fetchCompanyLeaveTypes = async (page = 1, perPage = 25) => {
    const response = await api.get('/shift_planning/company_leave_types', {
        params: { page, per_page: perPage }
    });
    return response.data;
};

export const createLeaves = async (leaves) => {
    const response = await api.post('/shift_planning/create_leaves', { leaves });
    console.log("Leave params", leaves)
    console.log("leaves data:", response.data);
    return response.data;
};