import React from 'react';
import { useDragLayer } from 'react-dnd';

const ShiftDragLayer = () => {
    const { itemType, isDragging, item, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    if (!isDragging) {
        return null;
    }

    return (
        <div style={{
            position: 'fixed',
            pointerEvents: 'none',
            zIndex: 100,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%'
        }}>
            <div style={{
                position: 'absolute',
                left: currentOffset?.x,
                top: currentOffset?.y,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '4px',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}>
                {item && (
                    <div>
                        {item.shiftId ? `Shift ID: ${item.shiftId}` : 'No shift'}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShiftDragLayer;