import React from 'react';
import './AddQuestionButton.css';

const AddQuestionButton = ({ onClick, title }) => {
    return (
        <button className="survey-add-question-button" onClick={onClick}>
            <span className="survey-plus-icon">+</span>
            {title}
        </button>
    );
};

export default AddQuestionButton;