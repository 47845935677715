import React, {useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './Question.css';
import {faTrash, faCheck, faGripVertical, faCopy, faLock, faLockOpen} from '@fortawesome/free-solid-svg-icons';
import AddQuestionButton from './AddQuestionButton';

const Question = ({question, questionTypes, onChange, onRemove, onCopy, position}) => {

    const handleChange = (e) => {
        const {name, value} = e.target;
        onChange(question.temp_id, name, value);
    };

    const handleAddAnswer = (e) => {
        e.preventDefault();
        if (question.answers.length < 10) {
            const newAnswer = {temp_id: Date.now(), title: '', value: '', is_correct: false};
            onChange(question.temp_id, 'answers', [...question.answers, newAnswer]);
        } else {
            alert('Çoktan seçmeli sorular en fazla 10 cevap içerebilir.');
        }
    };

    const handleAnswerChange = (answerTempId, name, value) => {
        const updatedAnswers = question.answers.map(a => a.temp_id === answerTempId ? {...a, [name]: value} : a);
        onChange(question.temp_id, 'answers', updatedAnswers);
    };

    const handleRemoveAnswer = (e, answerTempId) => {
        e.preventDefault();
        const updatedAnswers = question.answers.filter(a => a.temp_id !== answerTempId);
        onChange(question.temp_id, 'answers', updatedAnswers);
    };

    const handleCorrectAnswerChange = (e, answerTempId) => {
        e.preventDefault();
        const updatedAnswers = question.answers.map(answer =>
            answer.temp_id === answerTempId
                ? {...answer, is_correct: !answer.is_correct}
                : {...answer, is_correct: false}
        );
        onChange(question.temp_id, 'answers', updatedAnswers);
        e.target.blur();
    };

    const handleCopy = (e) => {
        e.preventDefault();
        onCopy(question.temp_id);
    };

    const toggleRequired = (e) => {
        e.preventDefault();
        onChange(question.temp_id, 'is_required', !question.is_required);
    };

    useEffect(() => {
        if (question.type === 'true_or_false' && !question.answers.length) {
            const initialAnswers = [
                {temp_id: 1, title: 'Doğru', value: 'Evet', is_correct: false},
                {temp_id: 2, title: 'Yanlış', value: 'Hayır', is_correct: false}
            ];
            onChange(question.temp_id, 'answers', initialAnswers);
        }

        if (question.type === 'free_text' && !question.answers.length) {
            const initialAnswer = {temp_id: Date.now(), title: '', value: '', is_correct: false};
            onChange(question.temp_id, 'answers', [initialAnswer]);
        }
    }, [question, onChange]);

    return (
        <div className="survey-question-container">
            <div className="survey-question-header">
                <div className="left">
                    <label>Soru {position}</label>
                    <input
                        type="text"
                        id={`text-${question.temp_id}`}
                        name="text"
                        value={question.text}
                        onChange={handleChange}
                    />
                </div>
                <div className="right">
                    <button
                        type="button"
                        className={`btn ${question.is_required ? 'btn-warning' : 'btn-outline-warning'} btn-sm`}
                        onClick={toggleRequired}
                    >
                        <FontAwesomeIcon icon={question.is_required ? faLock : faLockOpen}/>
                    </button>
                    <select
                        className="form-control"
                        id={`type-${question.temp_id}`}
                        name="type"
                        value={question.type}
                        onChange={handleChange}
                    >
                        <option value="">Soru Tipi</option>
                        {questionTypes.map(type => (
                            <option key={type.value} value={type.value}>
                                {type.title}
                            </option>
                        ))}
                    </select>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => onRemove(question.id, question.temp_id)}
                    >
                        <FontAwesomeIcon icon={faTrash}/>
                    </button>
                    <button
                        type="button"
                        className="btn btn-info"
                        onClick={handleCopy}
                    >
                        <FontAwesomeIcon icon={faCopy}/>
                    </button>
                    <span className="drag-handle">
                        <FontAwesomeIcon icon={faGripVertical}/>
                    </span>
                </div>
            </div>

            {question.type === 'multiple_choice' && (
                <div className="answers-section">
                    {question.answers.map((answer, index) => (
                        <div key={answer.temp_id} className="answer-item">
                            <input
                                type="text"
                                name="title"
                                style={{width: '100%', borderRadius: '20', marginLeft: '50px'}}
                                value={answer.title}
                                onChange={(e) => handleAnswerChange(answer.temp_id, 'title', e.target.value)}
                            />
                            <button
                                type="button"
                                className={`survey-btn ${answer.is_correct ? 'survey-btn-correct' : 'survey-btn-outline-correct'}`}
                                onClick={(e) => handleCorrectAnswerChange(e, answer.temp_id)}
                            >
                                <FontAwesomeIcon icon={faCheck} className={answer.is_correct ? 'survey-icon-white' : ''} />
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={(e) => handleRemoveAnswer(e, answer.temp_id)}
                            >
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>
                        </div>
                    ))}
                    <AddQuestionButton onClick={handleAddAnswer} title={"Seçenek Ekle"}/>
                </div>
            )}

            {question.type === 'true_or_false' && (
                <div className="survey-true-false-options">
                    {question.answers.map((answer, index) => (
                        <div key={answer.temp_id} className="survey-true-or-false-container">
                            <button
                                type="button"
                                className={`survey-btn ${answer.is_correct ? 'survey-btn-correct' : 'survey-btn-outline-correct'}`}
                                onClick={(e) => handleCorrectAnswerChange(e, answer.temp_id)}
                            >
                                <FontAwesomeIcon icon={faCheck} className={answer.is_correct ? 'survey-icon-white' : ''} />
                            </button>
                            <label style={{marginBottom: 'unset'}}>{answer.title}</label>
                        </div>
                    ))}
                </div>
            )}

            {question.type === 'free_text' && (
                <div className="survey-free-text-answer">
                    <input
                        type="text"
                        className="survey-form-control"
                        placeholder="Kullanıcı bu alana cevap yazacak"
                        value={question.answers[0]?.title || ''}
                        onChange={(e) => handleAnswerChange(question.answers[0]?.temp_id, 'title', e.target.value)}
                        disabled  // Bu satırı ekledik
                    />
                </div>
            )}
        </div>
    );
};

export default Question;
