import {Controller} from "@hotwired/stimulus"
import Flatpickr from 'stimulus-flatpickr'
import {Turkish} from 'flatpickr/dist/l10n/tr.js'

import 'flatpickr/dist/themes/airbnb.css'
// Connects to data-controller="flatpickr"
export default class extends Flatpickr {


    connect() {
        let enableTime = true;
        if (this.element.hasAttribute('data-enabletime')) {
            enableTime = this.element.dataset.enabletime === 'true';
        }


        //define locale and global flatpickr settings for all datepickers
        this.config = {
            locale: Turkish,
            enableTime: enableTime,
            dateFormat: "Y-m-d H:i",
            time_24hr: true
        };

        super.connect();
    }

    // all flatpickr hooks are available as callbacks in your Stimulus controller
    change(selectedDates, dateStr, instance) {
        if (this.element.hasAttribute('data-onchange') && this.element.dataset.onchange === 'true') {
            if (selectedDates.length > 0) {
                let selectedDate = selectedDates[0];
                let maxEndDate = new Date(selectedDate);
                let minEndDate = this.element.dataset.flatpickr_min_date

                if (this.element.hasAttribute('data-min-onchange-day')) {
                    minEndDate = new Date(selectedDate);
                    minEndDate.setDate(selectedDate.getDate() + parseInt(this.element.dataset.minOnchangeDay));
                }

                maxEndDate.setHours(23, 59, 0, 0);
                maxEndDate.setDate(selectedDate.getDate() + parseInt(this.element.dataset.maxOnchangeDay));

                this.updateAssignmentEndDate(maxEndDate, minEndDate);
            }
        }
    }

    updateAssignmentEndDate(maxEndDate, minEndDate) {
        let enableTime = true;
        if (this.element.hasAttribute('data-enabletime')) {
            enableTime = this.element.dataset.enabletime === 'true';
        }

        flatpickr(`#${this.element.dataset.endDateOnchangeId}`, {
            locale: Turkish,
            altInput: true,
            minDate: minEndDate,
            enableTime: enableTime,
            maxDate: maxEndDate
        })
    }
}