import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["select", "still", "flexible"]

    connect() {
        this.selected()
    }

    selected() {
        this.hideFields()
        switch (this.selectTarget.value) {
            case 'still':
                this.stillTargets.forEach((element) => {
                    element.classList.remove('d-none');
                });
                break;
            case 'flexible':
                this.flexibleTargets.forEach((element) => {
                    element.classList.remove('d-none');
                });
                break;
        }
    }

    hideFields() {
        this.stillTargets.forEach((element) => {
            element.classList.add('d-none')
        });
        this.flexibleTargets.forEach((element) => {
            element.classList.add('d-none')
        });
    }
}