import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
  }
  static targets = ["modal"];

  launchDemo(event) {
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.modalTarget,
        "modal"
    );
    modalController.open();

    console.log(event.currentTarget);
  }
}
