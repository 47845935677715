import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "forecast", "forecastCompletionDate" ]

  connect() {
    this.toggleForecastCompletionDate()
  }

  toggleForecastCompletionDate() {
    this.forecastCompletionDateTarget.style.display = this.forecastTarget.checked ? 'block' : 'none';
  }
}