// components/ShiftPlanning/ToolBar/DrawerComponent/DrawerComponent.jsx
import React from "react";
import "./DrawerComponent.css";

const DrawerComponent = ({ isOpen, onClose, children }) => {
  return (
    <div>
      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <div className='drawer-content'>
          <button className='drawer-close' onClick={onClose}>
            X
          </button>
          {children}
        </div>
      </div>
      {isOpen && <div className='drawer-overlay' onClick={onClose}></div>}
    </div>
  );
};

export default DrawerComponent;
