import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["masked", "full"]

    toggle() {
        this.maskedTargets.forEach((masked, index) => {
            const full = this.fullTargets[index];
            if (full.style.display === "none") {
                full.style.display = "block";
                masked.style.display = "none";
            } else {
                full.style.display = "none";
                masked.style.display = "block";
            }
        });
    }
}
