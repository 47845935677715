import React from 'react';
import SurveyForm from './SurveyForm';
import { useSurveyData } from '../../hooks/useSurveyData';

const EditSurvey = ({surveyId}) => {
    const { surveyData, isLoading, error } = useSurveyData(surveyId);

    if (isLoading) {
        return <div>Yükleniyor...</div>;
    }

    if (error) {
        return <div>Hata: {error.message}</div>;
    }

    const sortedSurveyData = surveyData ? {
        ...surveyData,
        questions: [...surveyData.questions].sort((a, b) => a.position - b.position)
    } : null;

    return (
        <div>
            {sortedSurveyData && <SurveyForm initialData={sortedSurveyData} />}
        </div>
    );
};

export default EditSurvey;