import React from 'react';
import SurveyForm from './SurveyForm';

const NewSurvey = () => {
    return (
        <div>
            <SurveyForm />
        </div>
    );
};

export default NewSurvey;