import React, { useCallback, useMemo, useEffect } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ShiftCell from "./ShiftCell";
import ShiftDragLayer from "./ShiftDragLayer";
import EmployeeInfo from "./EmployeeInfo";
import Skeleton from './Skeleton';
import { parse, format } from 'date-fns';
import * as shiftService from '../../services/shiftService';

import "./ShiftGrid.css";

const DailyTotals = ({ shifts, weekDays }) => {
    const calculateDailyTotal = (day) => {
        if (!shifts || typeof shifts !== 'object') {
            console.error("shifts is not an object:", shifts);
            return 0;
        }

        return Object.values(shifts)
            .reduce((total, userShifts) => {
                const dayShifts = userShifts[day] || [];
                return total + dayShifts.reduce((shiftTotal, shift) => {
                    return shiftTotal + (parseFloat(shift.calculated_shift_time) || 0);
                }, 0);
            }, 0);
    };

    return (
        <>
            <div className="daily-total-label">Toplam</div>
            {weekDays.map(day => (
                <div key={day.fullDate} className="daily-total-cell">
                    {calculateDailyTotal(day.displayDate).toFixed(2)} saat
                </div>
            ))}
        </>
    );
};

const ShiftGrid = React.memo(({
                                  selectedEmployees,
                                  setSelectedEmployees,
                                  selectedCells,
                                  setSelectedCells,
                                  shifts = {},
                                  leaves = {},
                                  holidayCalendarDays = {},
                                  weekDays = [],
                                  users = [],
                                  onDeleteShift,
                                  onDeleteLeave,
                                  isLoading,
                                  setDisplayedEmployeeCount,
                                  shiftData,
                                  lockApprovedShifts,
                                  currentWeekDate,
                                  viewMode,
                                  setShiftData,
                              }) => {
    useEffect(() => {
        setDisplayedEmployeeCount(users.length);
    }, [users, setDisplayedEmployeeCount]);

    const gridTemplateColumns = useMemo(() => {
        const employeeColumnWidth = '260px';
        const dayColumnWidth = viewMode === 'weekly' ? 'minmax(110px, 1fr)' : '110px';
        const repeatCount = viewMode === 'weekly' ? 7 : 28;
        return `${employeeColumnWidth} repeat(${repeatCount}, ${dayColumnWidth})`;
    }, [viewMode]);

    const handleEmployeeSelect = useCallback((employeeId) => {
        setSelectedEmployees((prev) => {
            const newSelectedEmployees = prev.includes(employeeId)
                ? prev.filter((id) => id !== employeeId)
                : [...prev, employeeId];

            setSelectedCells(prev => {
                if (newSelectedEmployees.includes(employeeId)) {
                    const newCells = weekDays.map(day => ({
                        employeeId,
                        date: day.fullDate
                    }));
                    return [...prev.filter(cell => cell.employeeId !== employeeId), ...newCells];
                } else {
                    return prev.filter(cell => cell.employeeId !== employeeId);
                }
            });

            return newSelectedEmployees;
        });
    }, [weekDays, setSelectedCells]);

    const handleCellSelect = useCallback((employeeId, day) => {
        setSelectedCells((prev) => {
            const formattedDate = typeof day === 'string'
                ? format(parse(day, 'EEE d', new Date(currentWeekDate)), 'yyyy-MM-dd')
                : day.fullDate;

            const existingCell = prev.find(cell =>
                cell.employeeId === employeeId && cell.date === formattedDate
            );

            if (existingCell) {
                return prev.filter(cell =>
                    !(cell.employeeId === employeeId && cell.date === formattedDate)
                );
            } else {
                return [...prev, { employeeId, date: formattedDate }];
            }
        });
    }, [currentWeekDate]);

    const handleDaySelect = useCallback((day) => {
        setSelectedCells((prev) => {
            const isDayFullySelected = users.every(user =>
                prev.some(cell => cell.employeeId === user.id && cell.date === day.fullDate)
            );

            if (isDayFullySelected) {
                return prev.filter(cell => cell.date !== day.fullDate);
            } else {
                const newCells = users.map(user => ({
                    employeeId: user.id,
                    date: day.fullDate
                })).filter(newCell =>
                    !prev.some(cell => cell.employeeId === newCell.employeeId && cell.date === newCell.date)
                );
                return [...prev, ...newCells];
            }
        });
    }, [users]);

    const isDaySelected = useCallback((day) => {
        return users.every((user) =>
            selectedCells.some(cell =>
                cell.employeeId === user.id &&
                cell.date === day.fullDate
            )
        );
    }, [users, selectedCells]);

    const handleMoveShift = useCallback(async (item, newEmployeeId, newDate) => {
        console.log('Move shift started:', { item, newEmployeeId, newDate });
        try {
            const movedShifts = await Promise.all(item.shifts.map(async (shift) => {
                console.log('Processing shift:', shift);
                const response = await shiftService.moveShift({
                    shiftId: shift.id,
                    oldEmployeeId: item.employeeId,
                    oldDate: item.date,
                    newEmployeeId,
                    newDate
                });
                console.log('Move shift response:', response);

                if (response.success) {
                    return response.shift;
                } else {
                    throw new Error(response.message || 'Vardiya taşıma işlemi başarısız oldu.');
                }
            }));

            console.log('Moved shifts:', movedShifts);

            setShiftData(prevData => {
                const newData = {...prevData};
                const oldDayKey = format(new Date(item.date), "EEE d");
                const newDayKey = format(new Date(newDate), "EEE d");

                // Eski konumdan shift'leri kaldır
                if (newData[item.employeeId] && newData[item.employeeId][oldDayKey]) {
                    newData[item.employeeId][oldDayKey] = newData[item.employeeId][oldDayKey].filter(
                        s => !item.shifts.some(itemShift => itemShift.id === s.id)
                    );
                }

                // Yeni konuma shift'leri ekle
                if (!newData[newEmployeeId]) newData[newEmployeeId] = {};
                if (!newData[newEmployeeId][newDayKey]) newData[newEmployeeId][newDayKey] = [];
                newData[newEmployeeId][newDayKey] = [
                    ...newData[newEmployeeId][newDayKey],
                    ...movedShifts
                ];

                console.log('Updated shift data:', newData);
                return newData;
            });

            console.log("Shifts moved successfully");
        } catch (error) {
            console.error("Error moving shifts:", error);
            alert(`Vardiya taşıma hatası: ${error.message}`);
        }
    }, [setShiftData]);

    const memoizedUsers = useMemo(() => users, [users]);

    if (isLoading) {
        return (
            <div className="shift-grid" style={{ gridTemplateColumns }}>
                <div className="employee-header"><Skeleton width={250} height={20}/></div>
                {weekDays.map((_, index) => (
                    <div key={index} className="day-header">
                        <Skeleton width={110} height={20}/>
                    </div>
                ))}
                <div className="daily-total-label">
                    <Skeleton width={250} height={20}/>
                </div>
                {weekDays.map((_, index) => (
                    <div key={index} className="daily-total-cell">
                        <Skeleton width={110} height={20}/>
                    </div>
                ))}
                {users.map((_, userIndex) => (
                    <React.Fragment key={userIndex}>
                        <div className="employee-info">
                            <Skeleton width={260} height={40}/>
                        </div>
                        {weekDays.map((_, cellIndex) => (
                            <div key={cellIndex} className="shift-cell">
                                <Skeleton width={110} height={30}/>
                            </div>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        );
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="shift-grid-container">
                <div className="shift-grid" style={{gridTemplateColumns}} data-view-mode={viewMode}>
                    <div className="grid-header">
                        <div className="employee-header">Çalışan</div>
                        {weekDays.map((day) => (
                            <div key={day.fullDate} className="day-header">
                                <span>{day.displayDate}</span>
                                <input
                                    type="checkbox"
                                    onChange={() => handleDaySelect(day)}
                                    checked={isDaySelected(day)}
                                />
                            </div>
                        ))}
                    </div>
                    <DailyTotals shifts={shifts} weekDays={weekDays}/>
                    {memoizedUsers.map((user) => (
                        <React.Fragment key={user.id}>
                            <EmployeeInfo
                                user={user}
                                isSelected={selectedEmployees.includes(user.id)}
                                onSelect={handleEmployeeSelect}
                                weekShifts={shiftData}
                            />
                            {weekDays.map((day) => (
                                <ShiftCell
                                    key={`${user.id}-${day.fullDate}`}
                                    shifts={shifts[user.id]?.[day.displayDate] || []}
                                    leaves={leaves[user.id]?.[day.displayDate] || []}
                                    holidayCalendarDays={holidayCalendarDays[day.fullDate] || []}
                                    isSelected={selectedCells.some(cell =>
                                        cell.employeeId === user.id &&
                                        cell.date === (typeof day === 'string' ? day : day.fullDate)
                                    )}
                                    onClick={() => handleCellSelect(user.id, day)}
                                    onDeleteShift={onDeleteShift}
                                    onDeleteLeave={onDeleteLeave}
                                    lockApprovedShifts={lockApprovedShifts}
                                    employeeId={user.id}
                                    date={day.fullDate}
                                    onMoveShift={handleMoveShift}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <ShiftDragLayer />
        </DndProvider>
    );
});

ShiftGrid.displayName = 'ShiftGrid';

export default ShiftGrid;