import React from "react";
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import "./ShiftCell.css";

const ShiftCell = React.memo(({
                                  shifts,
                                  leaves,
                                  holidayCalendarDays,
                                  isSelected,
                                  onClick,
                                  onDeleteShift,
                                  onDeleteLeave,
                                  lockApprovedShifts,
                                  employeeId,
                                  date,
                                  onMoveShift
                              }) => {
    const isApproved = shifts.some(shift => shift.status === "approved") || leaves.some(leave => leave.status === "approved");

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'SHIFT',
        item: () => ({
            shifts,
            employeeId,
            date
        }),
        canDrag: !isApproved,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [shifts, employeeId, date, isApproved]);

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'SHIFT',
        canDrop: () => !isApproved,
        drop: (item) => onMoveShift(item, employeeId, date),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [employeeId, date, onMoveShift, isApproved]);

    return (
        <div
            ref={drop}
            className={`shift-cell ${isSelected ? "selected" : ""} ${isApproved ? "approved" : ""} ${isOver ? "drag-over" : ""}`}
            onClick={onClick}
        >
            {(!shifts.length && !leaves.length && !holidayCalendarDays.length) && <div className="empty"></div>}

            {shifts.map((shift, index) => (
                <div
                    key={index}
                    ref={isApproved ? null : drag}
                    className={`shift-info ${shift.status === "approved" ? "approved" : ""}`}
                    style={{
                        borderColor: shift?.color || "#000",
                        opacity: isDragging ? 0.5 : 1,
                        cursor: isApproved ? 'not-allowed' : 'move',
                    }}
                >
                    <div className="shift-content">
                        <div className="shift-name">{shift?.name || "No shift"}</div>
                        <div className="shift-time">
                            {shift.calculated_shift_time} s
                            {shift.slack_time > 0 && ` (M: ${shift.slack_time} s)`}
                        </div>
                        {!lockApprovedShifts && !isApproved && (
                            <button className="delete-button" onClick={(e) => {
                                e.stopPropagation();
                                onDeleteShift(shift.id);
                            }}>
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </button>
                        )}
                    </div>
                </div>
            ))}

            {leaves.map((leave, index) => (
                <div
                    key={index}
                    className={`leave-info ${leave.status === "approved" ? "approved" : ""}`}
                    style={{
                        borderColor: leave?.color || '#ff0000',
                    }}
                >
                    <div className="shift-content">
                        <div className="leave-time">{leave?.title || "No leave type"}</div>
                    </div>
                </div>
            ))}

            {holidayCalendarDays.map((holiday, index) => (
                <div
                    key={index}
                    className="holiday-info"

                >
                    <div className="shift-content">
                        <div className="holiday-time">{holiday?.title || "Tatil"}</div>
                    </div>
                </div>
            ))}
        </div>
    );
});

ShiftCell.displayName = 'ShiftCell';

export default ShiftCell;