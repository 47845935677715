import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["checkInTime", "checkOutTime", "saturdayWorkCheckbox"]

    connect() {
        console.log('Shift controller connected');
        this.storeInitialValues();
        this.initializeState()
    }

    initializeState() {
        const isSaturdayWork = this.saturdayWorkCheckboxTarget.checked;
        this.setReadOnlyState(isSaturdayWork);

        if (isSaturdayWork) {
            this.clearValues();
        } else {
            this.restoreValues();
        }
    }

    toggleIsSaturdayWork(event) {
        console.log(event)
        const isSaturdayWork = event.target.checked;

        this.setReadOnlyState(isSaturdayWork);

        if (isSaturdayWork) {
            this.clearValues();
        } else {
            this.restoreValues();
        }
    }

    setReadOnlyState(isReadOnly) {
        console.log(isReadOnly)
        this.checkInTimeTarget.readOnly = isReadOnly;
        this.checkOutTimeTarget.readOnly = isReadOnly;

        this.checkInTimeTarget.disabled = isReadOnly;
        this.checkOutTimeTarget.disabled = isReadOnly;
    }

    clearValues() {
        this.checkInTimeTarget.value = null;
        this.checkOutTimeTarget.value = null;
    }

    restoreValues() {
        this.checkInTimeTarget.value = this.checkInTimeTarget.getAttribute('data-initial-value');
        this.checkOutTimeTarget.value = this.checkOutTimeTarget.getAttribute('data-initial-value');
    }

    storeInitialValues() {
        this.checkInTimeTarget.setAttribute('data-initial-value', this.checkInTimeTarget.value);
        this.checkOutTimeTarget.setAttribute('data-initial-value', this.checkOutTimeTarget.value);
    }
}
