import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["halfDay", "pacs", "webShift", "shift", "workingDay"]

    connect() {
        this.toggleHalfDayShift()  // Ensures proper display on initial load
    }

    toggleHalfDayShift() {
        const shiftContainer = document.getElementById("shiftContainer");
        const workingDayContainer = document.getElementById("workingDayContainer");
        const halfDayChecked = this.halfDayTarget.checked;
        const pacsChecked = this.pacsTarget.checked;
        const webShiftChecked = this.webShiftTarget.checked;

        // Reset display states
        workingDayContainer.style.display = "none";
        shiftContainer.style.display = "none";

        // Determine which containers to show
        if (halfDayChecked) {
            if (pacsChecked && webShiftChecked) {
                // If is_half_day, is_pacs, and is_web_shift are checked, show both containers
                workingDayContainer.style.display = "block";
                shiftContainer.style.display = "block";
            } else if (pacsChecked) {
                // Only is_pacs is checked with is_half_day
                workingDayContainer.style.display = "block";
            } else if (webShiftChecked) {
                // Only is_web_shift is checked with is_half_day
                shiftContainer.style.display = "block";
            }
        }
    }
}
