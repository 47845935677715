import axios from 'axios';

const API_BASE_URL = '/enum_values';

export const fetchEnumValues = async (enum_type, model_name) => {
    try {
        const result = await axios.get(`${API_BASE_URL}?enum_type=${enum_type}&model_name=${model_name}`);
        return result.data;
    } catch (error) {
        console.error("Error fetching enum values:", error);
        throw error;
    }
};