import { Controller } from "@hotwired/stimulus"
import FlatpickrDate from 'stimulus-flatpickr'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import 'flatpickr/dist/themes/airbnb.css'
// Connects to data-controller="flatpickr"
export default class extends FlatpickrDate {


  initialize() {
    this.config = {
      locale: Turkish,
      dateFormat: "Y-m-d H:i"
    };
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    console.log('the callback returns the selected dates', selectedDates)
    console.log('but returns it also as a string', dateStr)
    console.log('and the flatpickr instance', instance)
  }
}
