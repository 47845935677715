// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Import Axios and set CSRF token
import axios from "axios";

// Set CSRF token in Axios
const tokenElement = document.querySelector('meta[name="csrf-token"]');
if (tokenElement) {
    const csrfToken = tokenElement.getAttribute('content');
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
}

// Core libraries
require("turbolinks").start();
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

// jQuery (as a read only property so browser extensions can't clobber it)
const jquery = require("jquery");
const descriptor = { value: jquery, writable: false, configurable: false };
Object.defineProperties(window, { $: descriptor, jQuery: descriptor });

// App libraries
require("bootstrap");
require("jquery.nicescroll");
require("bootstrap-daterangepicker");
require("select2");
require("multiple-select");
require("multiple-select/dist/multiple-select-locale-all");
require("cleave.js");
require("waypoints/lib/noframework.waypoints");
require("waypoints/lib/shortcuts/infinite");
require("@nathanvda/cocoon");
// require("cookies_eu");

window.iziToast = require("izitoast");

require('./nested-forms/addFields');
require('./nested-forms/removeFields');
// Stisla
require("vendors/stisla/stisla");
require("vendors/stisla/scripts");

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();
import "direct_uploads";

// Application
require("app").start();
import "chartkick/chart.js";
import "controllers";

require("trix");
require("@rails/actiontext");

document.addEventListener("trix-file-accept", (e) => {
    alert("File attachment not supported!");
    e.preventDefault();
});

// Import React and ReactDOM
import React from "react";
import ReactDOM from "react-dom";

// Load all components inside the components directory
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
