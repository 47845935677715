// app/javascript/controllers/custom_time_picker_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "hoursPicker", "minutesPicker"]

  connect() {
    this.initializePicker()
    debugger;
    this.inputTarget.addEventListener('focus', this.showPicker.bind(this))
    this.inputTarget.addEventListener('blur', this.handleBlur.bind(this))
    this.inputTarget.addEventListener('keydown', this.handleKeydown.bind(this))
    document.addEventListener('click', this.handleDocumentClick.bind(this))
  }

  initializePicker() {
    const [hours, minutes] = this.inputTarget.value.split(':').map(Number)
    this.hoursPickerTarget.value = hours || 0
    this.minutesPickerTarget.value = minutes || 0
  }

  showPicker() {
    this.element.querySelector('.custom-time-picker').style.display = 'flex'
  }

  hidePicker() {
    this.element.querySelector('.custom-time-picker').style.display = 'none'
    this.updateInputValue()
  }

  handleBlur(event) {
    // Delay hiding to allow for clicks on the picker
    setTimeout(() => {
      if (!this.element.contains(document.activeElement)) {
        this.hidePicker()
      }
    }, 100)
  }

  handleDocumentClick(event) {
    if (!this.element.contains(event.target)) {
      this.hidePicker()
    }
  }

  updateHours(event) {
    let hours = parseInt(event.target.value, 10)
    if (isNaN(hours)) hours = 0
    this.hoursPickerTarget.value = hours
    this.updateInputValue()
  }

  updateMinutes(event) {
    let minutes = parseInt(event.target.value, 10)
    if (isNaN(minutes)) minutes = 0
    if (minutes > 59) minutes = 59
    this.minutesPickerTarget.value = minutes
    this.updateInputValue()
  }

  updateInputValue() {
    const hours = this.hoursPickerTarget.value.toString().padStart(2, '0')
    const minutes = this.minutesPickerTarget.value.toString().padStart(2, '0')
    this.inputTarget.value = `${hours}:${minutes}`
  }

  handleKeydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.hidePicker()
    }
  }
}
