// ScheduleFooter.jsx
import React from 'react';

const ScheduleFooter = ({ displayedEmployeeCount }) => {
    return (
        <footer className="shift-footer">
            <p>
                Gösterilen {displayedEmployeeCount} kişi - SC: Sicil No, B: Brüt, N: Net, M: Mola
            </p>
        </footer>
    );
};

export default ScheduleFooter;
