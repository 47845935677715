import { Controller } from "stimulus"
import Highcharts from "highcharts"
import Exporting from "highcharts/modules/exporting"
import ExportData from "highcharts/modules/export-data"
import Accessibility from "highcharts/modules/accessibility"

Exporting(Highcharts)
ExportData(Highcharts)
Accessibility(Highcharts)
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default class extends Controller {
    static targets = [
        "genderDistribution",
        "ageDistribution",
        "pieChart",
        "experienceDistribution",
        "employeeTypeDistribution",
        "educationDistribution",
        "departmentUserDistribution",
        "employeesByBranch",
        "feedbackStatusCounts",
        "feedbackCategoryDistribution",
        "moodOverTime",
        "passwordResetRequestsOverTime",
        "twoFactorAuthenticationUsage",
        "lastSignInFrequency",
        "accountActivationStatus",
        "employeeCountOverTime",
        "averageLeaveBalance",
        "monthlyLeaveRequests",
        "jobPositionFillRate",
        "genderExperienceLevels",
        "yearDistribution",
        "monthDistribution",
        "educationDistribution",
        "leaversDistribution",
        "departmentLeaversDistribution",
        "assetDistributionByDepartment",
        "assetDistributionByBranch",
        "assetStatusDistribution",
        "assetCategoryDistribution",
        "maintenanceDueAssets",
        "platformDistribution",
        "brandDistribution",
        "usersWithMultiplePhones",
        "brandDistributionByBranchAndDepartment",
        "branchUserPhoneCounts",
        "totalLeaveDays",
        "leaveTypeDistribution",
        "approvalStatus",
        "leaveByDepartment",
        "topLeaveUsers",
        "leaveTrends",
        "leaveRejectionReasons",
        "manualEntriesCount",
        "manualEntriesByMonth",
        "topManualUsers",
        "futureEntries",
        "topManualUsersByBranch",
        "dailyEntriesByBranch",
        "manualEntriesByBranchLastMonth"
    ]

    connect() {
        fetch(this.data.get("url"), {
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json()
            })
            .then(data => {
                console.log("Data fetched: ", data);  // JSON verisini kontrol etmek için konsola yazdırma
                // Employee Demographics
                if (this.hasGenderDistributionTarget) this.renderPieChart(this.genderDistributionTarget, data.gender_distribution)
                if (this.hasAgeDistributionTarget) this.renderPieChart(this.ageDistributionTarget, data.age_distribution)
                if (this.hasPieChartTarget) this.renderPieChart(this.pieChartTarget, data.pie_chart_data)
                if (this.hasExperienceDistributionTarget) this.renderPieChart(this.experienceDistributionTarget, data.experience_distribution)
                if (this.hasEmployeeTypeDistributionTarget) this.renderPieChart(this.employeeTypeDistributionTarget, data.employee_type_distribution)
                if (this.hasEducationDistributionTarget) this.renderPieChart(this.educationDistributionTarget, data.education_distribution)
                if (this.hasDepartmentUserDistributionTarget) this.renderColumnChart(this.departmentUserDistributionTarget, data.department_user_distribution)
                if (this.hasEmployeesByBranchTarget) this.renderColumnChart(this.employeesByBranchTarget, data.employees_by_branch, 'Employees Count')

                // Employee Activity and Engagement
                if (this.hasFeedbackStatusCountsTarget) this.renderPieChart(this.feedbackStatusCountsTarget, data.feedback_status_counts)
                if (this.hasFeedbackCategoryDistributionTarget) this.renderPieChart(this.feedbackCategoryDistributionTarget, data.feedback_category_distribution)
                if (this.hasMoodOverTimeTarget) this.renderLineChart(this.moodOverTimeTarget, data.mood_over_time, 'Mood Over Time')
                if (this.hasPasswordResetRequestsOverTimeTarget) this.renderLineChart(this.passwordResetRequestsOverTimeTarget, data.password_reset_requests_over_time, 'Password Reset Requests Over Time')
                if (this.hasTwoFactorAuthenticationUsageTarget) this.renderPieChart(this.twoFactorAuthenticationUsageTarget, data.two_factor_authentication_usage)
                if (this.hasLastSignInFrequencyTarget) this.renderLineChart(this.lastSignInFrequencyTarget, data.last_sign_in_frequency, 'Last Sign-In Frequency')
                if (this.hasAccountActivationStatusTarget) this.renderPieChart(this.accountActivationStatusTarget, data.account_activation_status)

                // Employee Statistics and Time Series
                if (this.hasEmployeeCountOverTimeTarget) this.renderLineChart(this.employeeCountOverTimeTarget, data.employee_count_over_time, 'Employee Count Over Time')
                if (this.hasAverageLeaveBalanceTarget) this.renderLineChart(this.averageLeaveBalanceTarget, data.average_leave_balance, 'Average Leave Balance')
                if (this.hasMonthlyLeaveRequestsTarget) this.renderLineChart(this.monthlyLeaveRequestsTarget, data.monthly_leave_requests, 'Monthly Leave Requests')

                // Department and Position Analysis
                if (this.hasDepartmentUserDistributionTarget) this.renderColumnChart(this.departmentUserDistributionTarget, data.department_user_distribution)
                if (this.hasJobPositionFillRateTarget) this.renderColumnChart(this.jobPositionFillRateTarget, data.job_position_fill_rate, 'Job Position Fill Rate')
                if (this.hasEmployeesByBranchTarget) this.renderColumnChart(this.employeesByBranchTarget, data.employees_by_branch, 'Employees Count')

                // Feedback and Performance Evaluation
                if (this.hasGenderExperienceLevelsTarget) this.renderColumnChart(this.genderExperienceLevelsTarget, data.gender_experience_levels)

                // Employee Turnover Analysis
                if (this.hasYearDistributionTarget) this.renderColumnChart(this.yearDistributionTarget, data.year_distribution, 'Year Distribution')
                if (this.hasMonthDistributionTarget) this.renderColumnChart(this.monthDistributionTarget, data.month_distribution, 'Month Distribution')
                if (this.hasEducationDistributionTarget) this.renderPieChart(this.educationDistributionTarget, data.education_distribution)
                if (this.hasLeaversDistributionTarget) this.renderColumnChart(this.leaversDistributionTarget, data.leavers_distribution, 'Leavers Distribution')
                if (this.hasDepartmentLeaversDistributionTarget) this.renderColumnChart(this.departmentLeaversDistributionTarget, data.department_leavers_distribution, 'Department Leavers Distribution')

                // Asset Management
                if (this.hasAssetDistributionByDepartmentTarget) this.renderColumnChart(this.assetDistributionByDepartmentTarget, data.asset_distribution_by_department, 'Varlık Sayısı')
                if (this.hasAssetDistributionByBranchTarget) this.renderColumnChart(this.assetDistributionByBranchTarget, data.asset_distribution_by_branch, 'Varlık Sayısı')
                if (this.hasAssetStatusDistributionTarget){
                    const translations = JSON.parse(this.assetStatusDistributionTarget.dataset.translations);
                    this.translatedRenderPieChart(this.assetStatusDistributionTarget, data.asset_status_distribution, translations)
                }
                if (this.hasAssetCategoryDistributionTarget) this.renderColumnChart(this.assetCategoryDistributionTarget, data.asset_category_distribution, 'Varlık Sayısı')
                if (this.hasMaintenanceDueAssetsTarget) this.renderPieChart(this.maintenanceDueAssetsTarget, data.maintenance_due_assets)

                // Device Management
                if (this.hasPlatformDistributionTarget) this.renderPieChart(this.platformDistributionTarget, data.platform_distribution)
                if (this.hasBrandDistributionTarget) this.renderColumnChart(this.brandDistributionTarget, data.brand_distribution, 'Telefon Sayısı')
                if (this.hasUsersWithMultiplePhonesTarget) {
                    this.renderPhoneColumnChart(this.usersWithMultiplePhonesTarget, data.users_with_multiple_phones, 'Telefon Sayısı');
                };
                if (this.hasBrandDistributionByBranchAndDepartmentTarget) this.renderComplexChart(this.brandDistributionByBranchAndDepartmentTarget, data.brand_distribution_by_branch_and_department)
                if (this.hasBranchUserPhoneCountsTarget) this.renderBranchUserPhoneCountsChart(this.branchUserPhoneCountsTarget, data.branch_user_phone_counts)

                // Leave Management
                if (this.hasTotalLeaveDaysTarget) this.renderTotalLeaveDaysChart(data.totalLeavesCount)
                if (this.hasLeaveTypeDistributionTarget) this.renderPieChart(this.leaveTypeDistributionTarget, data.leavesByType)
                if (this.hasApprovalStatusTarget) this.renderColumnChart(this.approvalStatusTarget, data.leavesByStatus);
                if (this.hasLeaveByDepartmentTarget) this.renderColumnChart(this.leaveByDepartmentTarget, data.departmentLeaveStatistics);
                if (this.hasTopLeaveUsersTarget) this.renderTopLeaveUsersChart(data.topLeaveUsers);
                if (this.hasLeaveTrendsTarget) this.renderLineChart(this.leaveTrendsTarget, data.leaveTrends);
                if (this.hasLeaveRejectionReasonsTarget) this.renderLeaveRejectionReasonsChart(data.leaveRejectionReasons);
                // Entries
                if (this.hasManualEntriesCountTarget) this.renderPieChart(this.manualEntriesCountTarget, data.manualEntriesCount);
                if (this.hasManualEntriesByMonthTarget) this.renderLineChart(this.manualEntriesByMonthTarget, data.manualEntriesByMonth, 'Manual Entries by Month');
                if (this.hasTopManualUsersTarget) this.renderColumnChartWithNames(this.topManualUsersTarget, data.topManualUsers, 'Top Manual Users');
                if (this.hasFutureEntriesTarget) this.renderColumnChart(this.futureEntriesTarget, data.futureEntries, 'Future Entries');
                if (this.hasTopManualUsersByBranchTarget) this.renderColumnChart(this.topManualUsersByBranchTarget, data.topManualUsersByBranch, 'Top Manual Users by Branch');
                if (this.hasDailyEntriesByBranchTarget) this.renderLineChart(this.dailyEntriesByBranchTarget, data.dailyEntriesByBranch, 'Daily Entries by Branch');
                if (this.hasManualEntriesByMonthNamesTarget) this.renderMonthlyLineChart(this.manualEntriesByMonthNamesTarget, data.manualEntriesByMonth);

            })
            .catch(error => console.error('Error fetching data:', error))
    }

    renderPieChart(target, data) {
        Highcharts.chart(target, {
            chart: { type: 'pie' },
            title: { text: null },
            exporting: { enabled: true },
            series: [{
                name: 'Count',
                colorByPoint: true,
                data: Object.keys(data).map(key => ({
                    name: key,
                    y: data[key]
                }))
            }]
        })
    }

    translatedRenderPieChart(target, data, translations) {
        Highcharts.chart(target, {
            chart: { type: 'pie' },
            title: { text: null },
            exporting: { enabled: true },
            series: [{
                name: 'Count',
                colorByPoint: true,
                data: Object.keys(data).map(key => ({
                    name: translations[key] || key,
                    y: data[key]
                }))
            }]
        })
    }

    renderColumnChart(target, data, yAxisTitle = 'Count') {
        Highcharts.chart(target, {
            chart: { type: 'column' },
            title: { text: null },
            exporting: { enabled: true },
            xAxis: { categories: Object.keys(data) },
            yAxis: { title: { text: yAxisTitle } },
            series: [{
                name: 'Count',
                data: Object.values(data)
            }]
        })
    }

    renderLineChart(target, data, title) {
        Highcharts.chart(target, {
            chart: { type: 'line' },
            title: { text: null },
            exporting: { enabled: true },
            xAxis: { categories: Object.keys(data) },
            yAxis: { title: { text: title } },
            series: [{
                name: 'Count',
                data: Object.values(data)
            }]
        })
    }


    renderPhoneColumnChart(target, data, yAxisTitle = 'Telefon Sayısı') {
        Highcharts.chart(target, {
            chart: { type: 'column' },
            title: { text: null },
            exporting: { enabled: true },
            xAxis: {
                categories: data.map(item => item.name), // Mapping names to x-axis
                title: { text: 'Ad Soyad' }
            },
            yAxis: {
                title: { text: yAxisTitle }, // Title for y-axis
                min: 0
            },
            series: [{
                name: 'Telefon Sayısı',
                data: data.map(item => item.y) // Mapping counts to y-axis
            }],
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true
                    }
                }
            }
        });
    }

    renderComplexChart(target, data) {
        const categories = [...new Set(data.map(item => `${item.branch_id}-${item.department_id}`))];
        const brands = [...new Set(data.map(item => item.brand))];
        const seriesData = brands.map(brand => ({
            name: brand,
            data: categories.map(category => {
                const item = data.find(d => `${d.branch_id}-${d.department_id}` === category && d.brand === brand);
                return item ? item.count : 0;
            })
        }));

        Highcharts.chart(target, {
            chart: { type: 'column' },
            title: { text: 'Brand Distribution by Branch and Department' },
            xAxis: {
                categories: categories,
                title: { text: 'Branch - Department' }
            },
            yAxis: {
                min: 0,
                title: { text: 'Count' }
            },
            tooltip: { shared: true },
            plotOptions: {
                column: {
                    dataLabels: { enabled: true }
                }
            },
            series: seriesData
        });
    }

    renderBranchUserPhoneCountsChart(target, data) {
        const filteredData = data.filter(item => item.branch_title !== 'Toplam Kullanıcı Sayısı');
        const categories = filteredData.map(item => item.branch_title);
        const userCounts = filteredData.map(item => item.user_count);
        const phoneCounts = filteredData.map(item => item.phone_count);

        Highcharts.chart(target, {
            chart: { type: 'line' },
            title: { text: null},
            xAxis: {
                categories: categories,
                title: { text: 'Branch' }
            },
            yAxis: {
                title: { text: 'Count' }
            },
            series: [{
                name: 'User Count',
                data: userCounts
            }, {
                name: 'Phone Count',
                data: phoneCounts
            }]
        });
    }


    renderTotalLeaveDaysChart(data) {
        Highcharts.chart(this.totalLeaveDaysTarget, {
            chart: { type: 'line' },
            title: { text: 'Total Leave Days Over Time' },
            series: [{ name: 'Total Leaves', data: data }]
        });
    }

    renderTopLeaveUsersChart(data) {
        Highcharts.chart(this.topLeaveUsersTarget, {
            chart: { type: 'column' },
            title: { text: 'Top Leave Users' },
            xAxis: { categories: data.map(item => item.name) },
            yAxis: { title: { text: 'Leave Days' } },
            series: [{
                name: 'Leave Days',
                data: data.map(item => item.leave_days)
            }]
        });
    }

    renderLeaveRejectionReasonsChart(data) {
        Highcharts.chart(this.leaveRejectionReasonsTarget, {
            chart: { type: 'pie' },
            title: { text: 'Reasons for Leave Rejections' },
            series: [{
                name: 'Rejections',
                colorByPoint: true,
                data: data
            }]
        });
    }

    renderMonthlyLineChart(target, data) {
        Highcharts.chart(target, {
            chart: {type: 'line'},
            title: {text: null},
            exporting: {enabled: true},
            xAxis: {categories: monthNames},
            yAxis: {title: {text: 'Manual Entries by Month'}},
            series: [{
                name: 'Count',
                data: data.map(item => item.y)
            }]
        });
    }

    renderColumnChartWithNames(target, data, yAxisTitle = 'Count') {
        Highcharts.chart(target, {
            chart: {type: 'column'},
            title: {text: null},
            exporting: {enabled: true},
            xAxis: {
                categories: data.map(item => item.name),
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {title: {text: yAxisTitle}},
            series: [{
                name: 'Count',
                data: data.map(item => item.y)
            }]
        });
    }

}
