// components/ShiftPlanning/ToolBar/ShiftModal/RotatingShiftDrawer.jsx
import React from "react";

const RotatingShiftDrawer = ({ onClose }) => {
  return (
    <div>
      <h2>Add Rotating Shift</h2>
      <p>Define the rotating shift details.</p>
      <form>
        {/* Form içeriği buraya eklenecek */}
        <button type='button' onClick={onClose}>
          Cancel
        </button>
        <button type='submit'>Add</button>
      </form>
    </div>
  );
};

export default RotatingShiftDrawer;
