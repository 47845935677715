import axios from "axios";

const API_BASE_URL = '/questions';


export const deleteQuestion = async (questionId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/${questionId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting question:", error);
        throw error;
    }
};

