import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["select", "branch", "group"]

    connect() {
        this.selected()
    }

    selected() {
        this.hideFields()
        switch (this.selectTarget.value) {
            case 'branch':
                this.showField(this.branchTarget)
                break;
            case 'branch_group':
                this.showField(this.groupTarget)
                break;
        }
    }

    hideFields() {
        this.branchTarget.classList.add('d-none')
        this.groupTarget.classList.add('d-none')
    }

    showField(target) {
        target.classList.remove('d-none')
    }
}
