import { Controller } from "@hotwired/stimulus"
import { GridStack } from "gridstack"
import "gridstack/dist/gridstack.min.css"
import "gridstack/dist/gridstack-extra.min.css"

// Connects to data-controller="dashboard-viewer"
export default class extends Controller {
    static values = { items: Array }
    connect() {
        this.grid = GridStack.init({
            column: 4,
            sizeToContent: true
        })

        this.grid.load(this.itemsValue)
    }
}