import React from 'react';
import './Skeleton.css';

const Skeleton = ({ width, height, circle }) => {
    return (
        <div
            className={`skeleton ${circle ? 'skeleton-circle' : ''}`}
            style={{ width, height }}
        ></div>
    );
};

export default Skeleton;