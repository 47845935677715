class removeFields {
  // This executes when the function is instantiated.
  constructor() {
    this.iterateLinks()
  }

  iterateLinks() {
    // Use event delegation to ensure any fields added after the page loads are captured.
    document.addEventListener('click', e => {
      if (e.target && e.target.classList == 'remove_fields btn btn-icon  btn-danger') {
        this.handleClick(e.target, e)
      }
    })
  }

  handleClick(link, e) {
    // Stop the function from executing if a link or event were not passed into the function.
    if (!link || !e) return
    // Prevent the browser from following the URL.
    e.preventDefault()
    // Find the parent wrapper for the set of nested fields.
    let fieldParent = link.closest('.nested-fields')
    // If there is a parent wrapper, find the hidden delete field.
    let deleteField = fieldParent
      ? fieldParent.querySelector('input[type="hidden"]')
      : null
    // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
    if (deleteField) {
      deleteField.value = 1
      fieldParent.style.display = 'none'
    }
  }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new removeFields())