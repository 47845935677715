import { useState, useEffect } from 'react';
import * as enumService from '../services/enumService';

export const useEnumData = (enumName, modelName) => {
    const [data, setData] = useState({ data: [] });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            try {
                const result = await enumService.fetchEnumValues(enumName, modelName);
                setData(result);
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [enumName, modelName]);

    return { data, isLoading };
}
