import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, startOfWeek, getWeek, format, addWeeks, subWeeks, differenceInWeeks, endOfWeek } from 'date-fns';
import tr from 'date-fns/locale/tr';

registerLocale('tr', tr);

const WeekNavigation = ({
                            onNavigateLeft,
                            onNavigateRight,
                            onToday,
                            currentWeek,
                            onDateChange,
                            isLoading,
                            viewMode,
                        }) => {
    const [startDate, setStartDate] = useState(currentWeek);

    useEffect(() => {
        setStartDate(currentWeek);
    }, [currentWeek]);

    const handleDateChange = (date) => {
        const weekStart = startOfWeek(date, { weekStartsOn: 1 });
        setStartDate(weekStart);
        onDateChange(weekStart);
    };

    const handleNavigate = (direction) => {
        const weeksToAdd = viewMode === '4weeks' ? 4 : 1;
        const daysToAdd = weeksToAdd * 7 * (direction === 'left' ? -1 : 1);
        const newDate = addDays(currentWeek, daysToAdd);
        onDateChange(startOfWeek(newDate, { weekStartsOn: 1 }));
    };

    const formatWeekDisplay = (date) => {
        const weekStart = startOfWeek(date, { weekStartsOn: 1 });
        const weekEnd = viewMode === '4weeks' ? endOfWeek(addWeeks(weekStart, 3), { weekStartsOn: 1 }) : endOfWeek(weekStart, { weekStartsOn: 1 });
        const startWeekNumber = getWeek(weekStart, { weekStartsOn: 1 });
        const endWeekNumber = getWeek(weekEnd, { weekStartsOn: 1 });
        const startMonth = format(weekStart, 'MMM', { locale: tr });
        const endMonth = format(weekEnd, 'MMM', { locale: tr });
        const startYear = format(weekStart, 'yyyy');
        const endYear = format(weekEnd, 'yyyy');

        if (viewMode === '4weeks') {
            return `${startWeekNumber}-${endWeekNumber}. Hafta: ${startMonth} ${startYear} - ${endMonth} ${endYear}`;
        } else {
            return `${startWeekNumber}. Hafta: ${startMonth} ${startYear}`;
        }
    };

    return (
        <div className='week-navigation'>
            <button
                className='nav-button'
                onClick={() => handleNavigate('left')}
                disabled={isLoading}
            >
                <FontAwesomeIcon icon={faChevronLeft}/>
            </button>
            <button
                className='today-button'
                onClick={onToday}
                disabled={isLoading}
            >
                Bugün
            </button>
            <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                customInput={<h2>{formatWeekDisplay(startDate)}</h2>}
                showWeekNumbers
                locale="tr"
                dateFormat='yyyy-MM-dd'
                startDate={startDate}
                onSelect={handleDateChange}
                disabled={isLoading}
            />
            <button
                className='nav-button'
                onClick={() => handleNavigate('right')}
                disabled={isLoading}
            >
                <FontAwesomeIcon icon={faChevronRight}/>
            </button>
        </div>
    );
};

export default WeekNavigation;