import React from 'react';
import './PlanFromHistoryModal.css';

const PlanFromHistoryModal = ({ isOpen, onClose, onConfirm, status, message }) => {
    if (!isOpen) return null;

    const weekOptions = [
        { value: 1, label: '1 hafta' },
        { value: 2, label: '2 hafta' },
        { value: 3, label: '3 hafta' },
        { value: 4, label: '1 ay' },
        { value: 8, label: '2 ay' },
        { value: 12, label: '3 ay' },
        { value: 16, label: '4 ay' },
        { value: 20, label: '5 ay' },
        { value: 24, label: '6 ay' },
    ];

    return (
        <div className="plan-from-history-modal">
            <div className="plan-from-history-modal-content">
                <span className="plan-from-history-modal-close" onClick={onClose}>&times;</span>
                {status === 'confirm' && (
                    <>
                        <h2>Geçmiş Verilere Göre Planla</h2>
                        <p>Hangi dönemin verilerini kullanmak istersiniz?</p>
                        <div className="plan-from-history-modal-options">
                            {weekOptions.map(option => (
                                <button
                                    key={option.value}
                                    className="plan-from-history-modal-option"
                                    onClick={() => onConfirm(option.value)}
                                >
                                    {option.label}
                                </button>
                            ))}
                        </div>
                        <button className="plan-from-history-modal-cancel" onClick={onClose}>İptal</button>
                    </>
                )}
                {status === 'loading' && (
                    <>
                        <h2>Planlama yapılıyor</h2>
                        <div className="plan-from-history-modal-loader"></div>
                        <p>Geçmiş veriler kullanılarak planlama yapılıyor...</p>
                    </>
                )}
                {status === 'success' && (
                    <>
                        <h2>Tamamlandı</h2>
                        <p>{message}</p>
                        <button
                            className="plan-from-history-modal-close-button"
                            onClick={onClose}
                        >
                            Kapat
                        </button>
                    </>
                )}
                {status === 'error' && (
                    <>
                        <h2>Hata</h2>
                        <p>{message}</p>
                        <button className="plan-from-history-modal-close-button" onClick={onClose}>Kapat</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default PlanFromHistoryModal;