import { useEffect, useState } from "react";
import { findSurvey } from "../services/surveyService";

export const useSurveyData = (survey_id) => {
    const [surveyData, setSurveyData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            try {
                const result = await findSurvey(survey_id);
                setSurveyData(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (survey_id) {
            loadData();
        }
    }, [survey_id]);

    return { surveyData, isLoading, error };
};
