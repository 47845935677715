import React, { useState, useEffect } from "react";
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchUserPermissions } from '../../../services/shiftService';

import {
    faCalendarWeek,
    faCalendarPlus,
    faPlus,
    faTrash,
    faCopy,
} from "@fortawesome/free-solid-svg-icons";
import DrawerComponent from "./DrawerComponent/DrawerComponent";
import AddShiftDrawer from "./ShiftModal/AddShiftDrawer";
import RestDayDrawer from "./ShiftModal/RestDayDrawer";
import RotatingShiftDrawer from "./ShiftModal/RotatingShiftDrawer";
import CopyModal from "./CopyModal/CopyModal";
import CopyPreviousWeekModal from "./CopyPreviousWeekModal/CopyPreviousWeekModal";
import DeleteShiftsModal from "./DeleteShiftsModal/DeleteShiftsModal";


import { parse, format, parseISO, isValid } from 'date-fns';



const ToolBar = ({
                     branches = [],
                     onBranchChange,
                     selectedBranch,
                     users,
                     selectedEmployees,
                     startDate,
                     endDate,
                     selectedCells,
                     onShiftsAdded,
                     setSelectedCells,
                     handleSelectAllEmployees,
                     onCopyPreviousWeek,
                     setSelectedEmployees,
                     onDeleteShifts,
                     onLeavesAdded,
                     viewMode,
                     onViewModeChange,
                     currentWeekDate,
                     onPlanFromHistory,
                 }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [currentDrawerContent, setCurrentDrawerContent] = useState(null);
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
    const [isCopyPreviousWeekModalOpen, setIsCopyPreviousWeekModalOpen] = useState(false);
    const [copyModalStatus, setCopyModalStatus] = useState('confirm');
    const [copyModalMessage, setCopyModalMessage] = useState('');
    const [query, setQuery] = useState("");
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [userPermissions, setUserPermissions] = useState({});

    const safeCurrentWeekDate = currentWeekDate && isValid(new Date(currentWeekDate))
        ? new Date(currentWeekDate)
        : new Date();


    const filteredBranches = branches
        ? query === ""
            ? branches
            : branches.filter((branch) =>
                branch.title.toLowerCase().includes(query.toLowerCase())
            )
        : [];


    useEffect(() => {
        const loadUserPermissions = async () => {
            const permissions = await fetchUserPermissions();
            setUserPermissions(permissions);
        };
        loadUserPermissions();
    }, []);

    const openDrawer = (content) => {
        setCurrentDrawerContent(content);
        setIsDrawerOpen(true);
    };

    const selectedEmployeeNames = users
        .filter(user => selectedEmployees.includes(user.id))
        .map(user => user.first_name + ' ' + user.last_name);

    const selectedCellDates = selectedCells.map(cell => new Date(cell.date));
    const validDates = selectedCellDates.filter(date => !isNaN(date.getTime()));
    const minDate = validDates.length > 0 ? format(new Date(Math.min(...validDates)), 'yyyy-MM-dd') : startDate;
    const maxDate = validDates.length > 0 ? format(new Date(Math.max(...validDates)), 'yyyy-MM-dd') : endDate;
    const selectedBranchObject = branches.find(branch => branch.id === selectedBranch) || null;
    const [deleteModalStatus, setDeleteModalStatus] = useState('confirm');
    const [deleteModalMessage, setDeleteModalMessage] = useState('');
    const handleCopyPreviousWeekClick = () => {
        setCopyModalStatus('confirm');
        setIsCopyPreviousWeekModalOpen(true);
    };

    const handlePlanFromHistory = (weeksAgo) => {
        onPlanFromHistory(weeksAgo);
        setIsPlanFromHistoryModalOpen(false);
    };
    const handleDeleteClick = () => {
        if (selectedCells.length > 0) {
            const shiftsToDelete = selectedCells.map(cell => ({
                user_id: cell.employeeId,
                date: typeof cell.date === 'object' ? cell.date.fullDate : cell.date
            }));
            setIsDeleteModalOpen(true);
        } else {
            setErrorMessage("Silinecek vardiya seçilmedi.");
            setShowError(true);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            setDeleteModalStatus('loading');
            const shiftsToDelete = selectedCells.map(cell => ({
                user_id: cell.employeeId,
                date: typeof cell.date === 'object' ? cell.date.fullDate : cell.date
            }));

            await onDeleteShifts(shiftsToDelete);
            setDeleteModalStatus('success');
            setDeleteModalMessage('Vardiyalar başarıyla silindi.');
            setSelectedCells([]);
            setSelectedEmployees([]);
        } catch (error) {
            setDeleteModalStatus('error');
            setDeleteModalMessage(error.message || 'Vardiyalar silinirken bir hata oluştu. Lütfen tekrar deneyin.');
        }
    };

    const handleConfirmCopyPreviousWeek = async () => {
        try {
            setCopyModalStatus('loading');
            await onCopyPreviousWeek();
            setCopyModalStatus('success');
            setCopyModalMessage('Vardiyalar başarıyla kopyalandı.');
            setSelectedCells([]);
            setSelectedEmployees([]);
        } catch (error) {
            console.error("Error copying previous week:", error);
            setCopyModalStatus('error');
            setCopyModalMessage('Vardiyalar kopyalanırken bir hata oluştu. Lütfen tekrar deneyin.');
        }
    };


    return (
        <div className='toolbar'>
            <div className='toolbar-left'>
                <Combobox
                    value={selectedBranch}
                    onChange={(value) => {
                        if (value && value.id) {
                            onBranchChange({target: {value: value.id}});
                        } else {
                            onBranchChange({target: {value: null}});
                        }
                    }}
                >
                    <div className="relative">
                        <ComboboxInput
                            className="select-selected"
                            placeholder="Şube Seçiniz"
                            displayValue={(branch) => branch?.title || ""}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <ComboboxOptions className="select-items">
                            {filteredBranches.length === 0 && query !== "" ? (
                                <div className="select-items">Şube mevcut değil</div>
                            ) : (
                                filteredBranches.map((branch) => (
                                    <ComboboxOption key={branch.id} value={branch} className="select-option">
                                        {branch.title}
                                    </ComboboxOption>
                                ))
                            )}
                        </ComboboxOptions>
                    </div>
                </Combobox>

                <select
                    className="view-mode-select"
                    value={viewMode}
                    onChange={(e) => onViewModeChange(e.target.value)}
                >
                    <option value="weekly">Haftalık</option>
                    <option value="4weeks">4 Haftalık</option>
                </select>
            </div>
            <div className='toolbar-center'>
                {selectedBranchObject && (
                    <h2 className='selected-branch-name'>{selectedBranchObject.title}</h2>
                )}
            </div>
            <div className='toolbar-right'>
                {selectedBranchObject && userPermissions.smart_planner && (
                    <button
                        onClick={onPlanFromHistory}
                        className='open-smart-shift-button'
                    >
                        Akıllı Planlama Yap
                        <span className='beta-tag'>BETA</span>
                    </button>
                )}
                {selectedBranchObject && (
                    <button className='open-shift-drawer-button' onClick={() => openDrawer("addShift")}>
                        <FontAwesomeIcon icon={faPlus}/> Vardiya
                    </button>
                )}
                {selectedBranchObject && (
                    <button className='open-rest-day-drawer-button' onClick={() => openDrawer("addRestDay")}>
                        <FontAwesomeIcon icon={faCalendarPlus}/> İzin
                    </button>
                )}
                {selectedCells.length > 0 && selectedBranch && (
                    <button
                        className='copy-previous-week-button'
                        onClick={handleCopyPreviousWeekClick}
                    >
                        <FontAwesomeIcon icon={faCopy}/> Geçen haftayı kopyala
                    </button>
                )}

                {/*<button*/}
                {/*    className='copy-button'*/}
                {/*    onClick={() => {*/}
                {/*        console.log("Copy button clicked");*/}
                {/*        setIsCopyModalOpen(true);*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <FontAwesomeIcon icon={faClone}/>*/}
                {/*</button>*/}
                {selectedBranchObject && (
                    <>
                        {selectedCells.length > 0 && (
                            <button
                                className="trash-button"
                                onClick={handleDeleteClick}
                                disabled={selectedCells.length === 0}
                            >
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>
                        )}
                        <input
                            type="checkbox"
                            checked={isAllSelected}
                            onChange={(e) => {
                                setIsAllSelected(e.target.checked);
                                handleSelectAllEmployees(e.target.checked);
                            }}
                        />
                        Tümünü Seç
                    </>
                )}
            </div>

            <DrawerComponent
                isOpen={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                }}
            >
                {currentDrawerContent === "addShift" && (
                    <AddShiftDrawer
                        onClose={() => {
                            setIsDrawerOpen(false);
                            setCurrentDrawerContent(null);
                        }}
                        selectedEmployees={selectedEmployeeNames}
                        selectedDays={selectedCells.map(cell => cell.day)}
                        startDate={minDate}
                        endDate={maxDate}
                        users={users}
                        selectedCells={selectedCells}
                        onShiftsAdded={onShiftsAdded}
                        currentWeekDate={safeCurrentWeekDate}

                    />
                )}
                {currentDrawerContent === "addRestDay" && (
                    <RestDayDrawer
                        isOpen={isDrawerOpen}
                        onClose={() => {
                            setIsDrawerOpen(false);
                            setCurrentDrawerContent(null);
                        }}
                        users={users}
                        selectedCells={selectedCells}
                        onLeavesAdded={onLeavesAdded}
                        currentWeekDate={safeCurrentWeekDate}

                    />
                )}
                {currentDrawerContent === "addRotatingShift" && (
                    <RotatingShiftDrawer onClose={() => setIsDrawerOpen(false)}/>
                )}
            </DrawerComponent>
            {isCopyModalOpen && (
                <CopyModal onClose={() => setIsCopyModalOpen(false)}/>
            )}
            <CopyPreviousWeekModal
                isOpen={isCopyPreviousWeekModalOpen}
                onClose={() => {
                    setIsCopyPreviousWeekModalOpen(false);
                    setCopyModalStatus('confirm');
                    setCopyModalMessage('');
                    setSelectedCells([]);
                    setSelectedEmployees([]);
                }}
                onConfirm={handleConfirmCopyPreviousWeek}
                status={copyModalStatus}
                message={copyModalMessage}
                shiftCount={selectedCells.length}
            />
            <DeleteShiftsModal
                isOpen={isDeleteModalOpen}
                onClose={() => {
                    setIsDeleteModalOpen(false);
                    setDeleteModalStatus('confirm');
                    setDeleteModalMessage('');
                }}
                onConfirm={handleConfirmDelete}
                shiftCount={selectedCells.length}
                employeeCount={new Set(selectedCells.map(cell => cell.employeeId)).size}
                status={deleteModalStatus}
                message={deleteModalMessage}
            />

        </div>
    );
};

export default ToolBar;