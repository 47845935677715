import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["select", "choice", "long"]

    connect() {
        this.selected()
    }

    selected() {
        this.hideFields()
        console.log(this.selectTarget.value)
        switch (this.selectTarget.value) {
            case 'single_choise':
                console.log('single_choise')
                this.choiceTarget.classList.remove('hidden')
                break;
            case 'multiple_choice':
                console.log('multiple_choice')
                this.choiceTarget.classList.remove('hidden')
                break;
            case 'free_text':
                console.log('free_text')
                this.longTarget.classList.remove('hidden')
                break;
        }
    }

    hideFields() {
        this.choiceTarget.classList.add('hidden')
        this.longTarget.classList.add('hidden')
    }
}