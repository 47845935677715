import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["bulkCheckBox", "allCheckBox"]

    connect() {
        this.selectedIds = []
    }

    clicked(event) {
        if (event.target.value === "all") {
            this.bulkCheckBoxTargets.forEach(checkbox => {
                checkbox.checked = true
                this.selectedIds.push(checkbox.value)
            })
        } else {
            this.allCheckBoxTarget.checked = false
            this.selectedIds = this.selectedIds.filter(id => id !== event.target.value)
        }
    }
}