import { Controller } from "@hotwired/stimulus"
import { GridStack } from "gridstack"
import "gridstack/dist/gridstack.min.css"
import "gridstack/dist/gridstack-extra.min.css"

// Connects to data-controller="dashboard"
export default class extends Controller {
    static targets = ["template"]
    static values =  { items: Array }
    connect() {
        this.grid = GridStack.init({
            column: 4,
            acceptWidgets: true,
            removable: "#remove-from-grid"
        })

        this.grid.load(this.itemsValue)
        const gridItems = document.querySelectorAll(".grid-stack .grid-stack-item")
        gridItems.forEach(gridItem => {
            gridItem.querySelector(".form-elements").dataset.classList.split(" ").forEach(className => {
                gridItem.classList.add(className)
            })
        })

        GridStack.setupDragIn(".newWidget", { appendTo: "body", helper: "clone" })

        this.grid.on('added', (e, items) => {
            items.forEach(item => {
                var content = document.createElement('div')
                content.innerHTML = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
                content.querySelector('[data-input="x"]').value = item.x
                content.querySelector('[data-input="y"]').value = item.y
                content.querySelector('[data-input="width"]').value = item.w
                content.querySelector('[data-input="height"]').value = item.h
                content.querySelector('[data-input="category"]').value = item.el.dataset.category
                item.el.querySelector('.form-elements').innerHTML = content.innerHTML
            })
        })

        this.grid.on('change', (e, items) => {
            items.forEach(item =>{
                item.el.querySelector('[data-input="x"]').value = item.x
                item.el.querySelector('[data-input="y"]').value = item.y
                item.el.querySelector('[data-input="width"]').value = item.w
                item.el.querySelector('[data-input="height"]').value = item.h
            })
        })

        this.grid.on('removed', (e, items) => {
            items.forEach(item => {
                item.el.classList.add("d-none")
                item.el.querySelector('[data-input="destroy"]').value = 1
                this.element.insertAdjacentElement("beforebegin", item.el.querySelector("input[name*='_destroy']"))
                var id = item.el.querySelector("input[name*='[id]']")
                this.element.insertAdjacentElement("beforebegin", id)
            })
        })

    }
}