import React from 'react';
import './ErrorModal.css';

const ErrorModal = ({ showError, errorMessage, onClose }) => {
    if (!showError) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h3>Bilgilendirme</h3>
                <p>{errorMessage}</p>
                <div className="button-group-right">
                    <button className="close-button" onClick={onClose}>Kapat</button>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;