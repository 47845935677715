import { Controller } from "stimulus";
import SlimSelect from "slim-select";
import "slim-select/dist/slimselect.css";

export default class extends Controller {
  static values = { url: String, active: String, excluded: String }

  connect() {
    let excludedArray = [];
    if (this.excludedValue && this.isJsonString(this.excludedValue)) {
      excludedArray = JSON.parse(this.excludedValue);
    }

    const select = new SlimSelect({
      select: this.element,
      placeholder: 'Seçiniz...',  // Corrected placeholder setting
      searchPlaceholder: 'Aramak için yazın...',  // Corrected search placeholder setting
      ajax: (search, callback) => {
        if (search.length < 3) {
          callback('En az 3 karakter giriniz.');
          return;
        }

        let url;
        try {
          url = new URL(this.urlValue);
        } catch (error) {
          callback(`Invalid URL: ${this.urlValue}`);
          return;
        }

        const params = { query: search, exclude: excludedArray };

        if (this.activeValue) {
          params.active = this.activeValue;
        }
        url.search = new URLSearchParams(params);

        fetch(url)
            .then(response => response.json())
            .then(data => {
              const filtered = data.filter(item => !excludedArray.includes(item.value));
              callback(filtered);
            })
            .catch(error => callback(`Error fetching data: ${error}`));
      }
    });

    select.onChange = () => {
      const selectedValues = select.selected();
      excludedArray = selectedValues;
      this.excludedValue = JSON.stringify(excludedArray);
    };
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}