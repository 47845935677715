import React, { useState, useEffect } from 'react';
import AddQuestionButton from "../Question/AddQuestionButton";
import Question from '../Question/Question';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useEnumData } from "../../hooks/useEnumData";
import { addSurvey, updateSurvey } from "../../services/surveyService";
import SurveyPreviewModal from './SurveyPreviewModal';
import {deleteQuestion} from "../../services/questionService";

const SurveyForm = ({ initialData }) => {
    const { data: questionTypes, isLoading: isLoadingQuestionTypes } = useEnumData('question_types', 'Question');
    const [isLoading, setIsLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [filteredQuestionTypes, setFilteredQuestionTypes] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        is_active: false,
    });
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        if (questionTypes) {
            const validTypes = ['true_or_false', 'free_text', 'multiple_choice'];
            const filteredTypes = questionTypes.data.filter(type => validTypes.includes(type.value));
            setFilteredQuestionTypes(filteredTypes);
        }
    }, [questionTypes]);

    useEffect(() => {
        if (initialData) {
            setFormData({
                name: initialData.name || '',
                description: initialData.description || '',
                is_active: initialData.is_active || false,
            });
            const questionsWithTempId = initialData.questions.map((q, index) => ({
                id: q.id,
                temp_id: q.id || Date.now() + index,
                text: q.name,
                type: q.question_type,
                position: q.position,
                is_required: q.required,
                answers: q.answers.map((a, aIndex) => ({
                    id: a.id,
                    temp_id: a.id || Date.now() + index + aIndex,
                    title: a.title,
                    value: a.value,
                    is_correct: a.is_correct
                }))
            }));
            setQuestions(questionsWithTempId);
        }
    }, [initialData]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleQuestionChange = (temp_id, name, value) => {
        setQuestions(prevQuestions => prevQuestions.map(q => q.temp_id === temp_id ? { ...q, [name]: value } : q));
    };

    const handleAddQuestion = () => {
        setQuestions(prevQuestions => [
            ...prevQuestions,
            {
                temp_id: Date.now(),
                text: '',
                type: 'single',
                answers: [],
                position: prevQuestions.length + 1,
                is_required: false
            }
        ]);
    };

    const handleCopyQuestion = (temp_id) => {
        const questionToCopy = questions.find(q => q.temp_id === temp_id);
        if (questionToCopy) {
            const newQuestion = {
                ...questionToCopy,
                id: undefined,
                temp_id: Date.now(),
                text: questionToCopy.text + ' (Kopya)',
                answers: questionToCopy.answers.map(a => ({
                    ...a,
                    id: undefined,
                    temp_id: Date.now() + Math.random()
                }))
            };
            setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
        }
    };

    const handleDeleteQuestion = async (questionId, tempId) => {
        try {
            if (questionId) {
                await deleteQuestion(questionId);
            }

            setQuestions(prevQuestions => {
                const updatedQuestions = prevQuestions.filter(q => q.temp_id !== tempId);
                return updatedQuestions.map((q, index) => ({ ...q, position: index + 1 }));
            });
        } catch (error) {
            console.error("Error deleting question:", error);
            alert("Soru silinirken bir hata oluştu.");
        }
    };

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const reorderedQuestions = Array.from(questions);
        const [movedQuestion] = reorderedQuestions.splice(source.index, 1);
        reorderedQuestions.splice(destination.index, 0, movedQuestion);

        const updatedQuestions = reorderedQuestions.map((q, index) => ({
            ...q,
            position: index + 1
        }));

        setQuestions(updatedQuestions);
    };

    const handlePreview = () => {
        setShowPreview(true);
    };

    const handleClosePreview = () => {
        setShowPreview(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.name.trim() === '' || formData.name.length > 100) {
            alert('Anket başlığı boş olamaz ve 100 karakterden uzun olamaz.');
            return;
        }

        if (formData.description.length > 500) {
            alert('Anket açıklaması 500 karakterden uzun olamaz.');
            return;
        }

        if (questions.length === 0) {
            alert('Ankete en az bir soru eklemelisiniz.');
            return;
        }

        for (const question of questions) {
            if (question.type === 'multiple_choice' && question.answers.length < 2) {
                alert('Çoktan seçmeli sorular en az iki cevap seçeneği içermelidir.');
                return;
            }
        }

        setIsLoading(true);
        const formattedData = {
            ...formData,
            status: 'draft',
            questions_attributes: questions.map((q, index) => ({
                id: q.id,
                temp_id: q.temp_id,
                name: q.text,
                question_type: q.type,
                position: index + 1,
                required: q.is_required,
                answers_attributes: q.answers.map(a => ({
                    id: a.id,
                    temp_id: a.temp_id,
                    title: a.title,
                    value: a.value,
                    is_correct: a.is_correct
                }))
            }))
        };

        console.log("Formatted Data:", formattedData);

        try {
            let response;
            if (initialData) {
                response = await updateSurvey(initialData.id, formattedData);
            } else {
                response = await addSurvey(formattedData);
            }

            console.log("Server Response:", response);

            if (response && response.redirect_url) {
                alert(initialData ? "Anket başarıyla güncellendi!" : "Anket başarıyla oluşturuldu!");
                window.location.href = response.redirect_url;
            } else {
                throw new Error("No survey data in the response");
            }
        } catch (error) {
            console.error("Error saving survey:", error);
            alert("Anket kaydedilirken bir hata oluştu.");
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoadingQuestionTypes) {
        return <div>Yükleniyor...</div>;
    }

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="questions">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Anket Adı</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Anket Açıklaması</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="is_active"
                                        name="is_active"
                                        checked={formData.is_active}
                                        onChange={handleInputChange}
                                    />
                                    <label className="custom-control-label" htmlFor="is_active">Aktif</label>
                                </div>
                            </div>

                            {questions.map((question, index) => (
                                <Draggable key={question.temp_id} draggableId={question.temp_id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`survey-draggable-question ${snapshot.isDragging ? 'dragging' : ''}`}
                                        >
                                            <Question
                                                onCopy={handleCopyQuestion}
                                                position={index + 1}
                                                question={question}
                                                questionTypes={filteredQuestionTypes}
                                                onChange={handleQuestionChange}
                                                onRemove={() => handleDeleteQuestion(question.id, question.temp_id)}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}

                            {provided.placeholder}

                            <div className="card-footer text-right pl-0">
                                <button type="button" className="btn btn-primary mr-2" onClick={handlePreview}>
                                    Önizle
                                </button>
                                <button type="submit" className="btn btn-success" disabled={isLoading}>
                                    {isLoading ? 'Kaydediliyor...' : (initialData ? 'Güncelle' : 'Oluştur')}
                                </button>
                            </div>
                        </form>
                        <AddQuestionButton onClick={handleAddQuestion} title={"Yeni Anket Sorusu Ekle"}/>
                    </div>
                )}
            </Droppable>
            <SurveyPreviewModal
                show={showPreview}
                handleClose={handleClosePreview}
                handleSave={handleSubmit}
                survey={{...formData, questions}}
                questionTypes={questionTypes.data}
            />
        </DragDropContext>
    );
};

export default SurveyForm;