import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["add_item", "template"]

    connect() {
        this.answerLimit = 10;
    }

    add_association(event) {
        event.preventDefault()
        var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
        this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
    }

    add_answer(event) {
        event.preventDefault();
        const answersCount = this.element.querySelectorAll(".nested-fields").length;

        if (answersCount < this.answerLimit) {
            const content = this.templateTarget.innerHTML.replace(/ANSWER_RECORD/g, new Date().getTime());
            this.add_itemTarget.insertAdjacentHTML('beforebegin', content);
        } else {
            alert("En fazla 10 cevap ekleyebilirsiniz.");
        }
    }
    remove_association(event) {
        event.preventDefault()
        let item = event.target.closest(".nested-fields")
        item.querySelector("input[name*='_destroy']").value = 1
        item.style.display = 'none'
    }
}