import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["select", "url", "content", "visual", "embedded"]

    connect() {
        this.selected()
    }

    selected() {
        this.hideFields()
        switch (this.selectTarget.value) {
            case 'url':
                this.urlTarget.classList.remove('d-none')
                break;
            case 'content':
                this.contentTarget.classList.remove('d-none')
                break;
            case 'visual':
                this.visualTarget.classList.remove('d-none')
                break;
            case 'embedded':
                this.embeddedTarget.classList.remove('d-none')
                break;
        }
    }

    hideFields() {
        console.log("hideFields method called")
        this.urlTarget.classList.add('d-none')
        this.contentTarget.classList.add('d-none')
        this.visualTarget.classList.add('d-none')
        this.embeddedTarget.classList.add('d-none')
    }
}
