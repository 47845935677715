import React, { useState, useEffect } from "react";
import WeekNavigation from './WeekNavigation';
import { startOfWeek } from 'date-fns';
import { fetchUserPermissions } from '../../services/shiftService';


const ScheduleHeader = ({ scheduleStatus, currentWeekDate, onDateChange, onApproveSchedule, isLoading, viewMode }) => {
    const [userPermissions, setUserPermissions] = useState({});

    useEffect(() => {
        const loadUserPermissions = async () => {
            const permissions = await fetchUserPermissions();
            setUserPermissions(permissions);
        };
        loadUserPermissions();
    }, []);

    const handleNavigate = (direction) => {
        const weeksToAdd = viewMode === '4weeks' ? 4 : 1;
        const daysToAdd = weeksToAdd * 7 * (direction === 'left' ? -1 : 1);
        const newDate = addDays(currentWeekDate, daysToAdd);
        onDateChange(startOfWeek(newDate, { weekStartsOn: 1 }));
    };
    const handleNavigateLeft = () => {
        const newDate = new Date(currentWeekDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        onDateChange(startOfWeek(newDate, { weekStartsOn: 1 }));
    };

    const handleNavigateRight = () => {
        const newDate = new Date(currentWeekDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        onDateChange(startOfWeek(newDate, { weekStartsOn: 1 }));
    };

    return (
        <header className="shift-header">
            <div className="shift-header-left">
                <h1>
                    Planlama <span
                    className={`status-badge ${scheduleStatus}`}> {scheduleStatus === 'approved' ? 'Onaylandı' : 'Taslak'}</span>
                </h1>
            </div>
            <div className="shift-header-center">
                <WeekNavigation
                    currentWeek={currentWeekDate}
                    onDateChange={onDateChange}
                    onNavigateLeft={() => handleNavigate('left')}
                    onNavigateRight={() => handleNavigate('right')}
                    onToday={() => onDateChange(startOfWeek(new Date(), {weekStartsOn: 1}))}
                    isLoading={isLoading}
                    viewMode={viewMode}
                />
            </div>
            <div className="shift-header-right">
                {userPermissions.approving_shifts && (
                    <button className="publish-button" onClick={onApproveSchedule}>Planı Onayla</button>
                )}
            </div>
        </header>
    );
};

export default ScheduleHeader;